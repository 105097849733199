@keyframes fade-in-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

%animate-element {
  animation: fade-in-animation ease 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

%post-avatar {
  width: 32px;
  height: 32px;
  background-color: $blue-pale;
  border-radius: 50%;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  color: $blue-moyen;
  justify-content: center;
  align-items: center;
  display: flex;
}

%controllers-tooltip {
  background-color: white;
  width: 200px;
  height: 40px;
  position: absolute;
  margin-left: -125px;
  margin-top: -50px;
  box-shadow: rgb(99 99 99 / 20%) 0 2px 9px 2px;

  &.show {
    display: inline-block;
  }

  &::after {
    content: " ";
    display: inline-block;
    position: relative;
    float: right;
    margin-right: 30px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
  }

  .buttons-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    height: 100%;
    gap: 8px;
  }

  button {
    background-color: transparent;
    display: inline-flex;
    color: $blue-fonce;
    gap: 4px;
    justify-content: center;
    align-items: center;
    border: 0;

    img {
      width: 15px;
    }
  }
}

%controllers-tooltip-thread {
  background-color: white;
  width: 290px;
  height: 40px;
  position: absolute;
  margin-left: -214px;
  margin-top: -50px;
  box-shadow: rgb(99 99 99 / 20%) 0 2px 9px 2px;

  &.show {
    display: inline-block;
  }

  &::after {
    content: " ";
    display: inline-block;
    position: relative;
    float: right;
    margin-right: 30px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
  }

  .buttons-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    height: 100%;
    gap: 8px;
  }

  button {
    background-color: transparent;
    display: inline-flex;
    color: $blue-fonce;
    gap: 4px;
    justify-content: center;
    align-items: center;
    border: 0;

    img {
      width: 15px;
    }
  }
}

%post-header {
  display: flex;
  align-items: center;
  gap: 16px;
}

%post-auth-name {
  font-size: 1rem;
  font-weight: bold;
  color: $blue-fonce;
}

.public-room,
.jesuisjecherche {
  transition: all 0.5s ease;
  color: $blue-fonce;

  $left-col-width: 400px;

  p {
    margin-bottom: 0;
  }

  & > .container {
    min-height: 100vh;
  }

  .pr-row {
    display: flex;
  }

  .post-header-unit-top {
    @extend %post-header;
  }

  .auth-name {
    @extend %post-auth-name;
  }

  .empty-message {
    width: 100%;
    display: flex;
    min-height: 300px;
    justify-content: center;
    align-items: center;
    color: #9cacb4;
  }

  .pr-row-left {
    padding: 32px 0;
    flex: 0 0 $left-col-width;
    border-right: 1px solid $gris-clair;

    .pr-tabs {
      display: flex;
      margin-bottom: 0;
      padding: 0 16px 0 6px;
      list-style: none;
      gap: 8px;
      border-bottom: 1px solid $gris-clair;
      font-family: "Open Sans", sans-serif;
      font-size: 1rem;
      height: 40px;
      align-items: flex-end;

      li {
        cursor: pointer;
        color: $blue-moyen;

        a {
          color: $blue-piv !important;
          padding: 8px 11px;
          font-size: 16px;
          display: block;
        }
      }

      .active {
        font-weight: bold;
        border-bottom: 4px solid $blue-piv;
        padding: 8px 16px;
        font-size: 16px;
      }
    }

    .pr-tabs-title {
      font-weight: bold;
      color: $blue-fonce;
      font-size: 21px;
      font-family: "open sans", sans-serif;
    }

    .pr-tabs-content {
      display: flex;
      flex-direction: column;

      .pr-tab-cont {
        display: none;
        padding-right: 16px;

        &.active {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .pr-tabs-list {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 32px 0 0;
      list-style: none;

      li {
        margin-right: 8px;
        margin-bottom: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid $gris-clair;
        background-color: white;
        padding-right: 8px;
        cursor: pointer;

        p {
          margin: 0;
        }

        &:hover,
        &.active {
          padding-top: 8px;
          padding-left: 10px;
          padding-right: 16px;
          margin: -8px 0 8px -8px;
          background-color: $blue-pale;
          font-weight: bold;
        }
      }
    }
  }

  .pr-row-right {
    padding: 0 0 32px;
    flex: 0 0 calc(100% - $left-col-width);

    .post-comment-controllers-tooltip {
      @extend %controllers-tooltip;
    }

    .post-comment-controllers-tooltip-thread {
      @extend %controllers-tooltip-thread;
    }

    .pr-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 16px 11px;
      border-bottom: 1px solid $gris-clair;

      & .fw-bold {
        font-family: "Open Sans", sans-serif;
        height: 44px;
        display: flex;
        align-items: flex-end;
        color: $blue-fonce;
        font-size: 18px;
      }

      .btn {
        padding: 5px 15px;
        font-weight: 500;
        margin: 0;
      }
    }

    .date-display {
      display: flex;
      font-size: 14px;
      color: $gris;
      border-bottom: 1px solid $gris-clair;
      justify-content: center;
      margin: 32px 0;

      & > div {
        margin-bottom: -8px;
        background-color: white;
        padding: 0 8px;
      }
    }

    .show-likes-section {
      display: flex;
      align-items: center;
      gap: 4px;
      font-weight: 500;

      .icon-heart {
        width: 15px;
      }
    }
  }

  .post-controllers {
    cursor: pointer;
    position: relative;

    [data-id] {
      padding: 8px;
    }

    .post-controllers-container {
      display: flex;
    }
  }

  .posts-section {
    margin: 16px;
    margin-top: 0;

    .post-unit {
      display: flex;
      gap: 16px;

      .post-avatar-col {
        .post-avatar {
          @extend %post-avatar;
        }
      }

      .post-content-col {
        background-color: $gris-pale;
        border: 1px solid $gris-clair;
        width: 100%;

        .post-header {
          padding: 8px 16px;
          display: flex;
          justify-content: space-between;
          font-size: 9pt;
          position: relative;

          .post-header-top {
            .auth-name {
              display: flex;
              gap: 16px;
            }
          }
        }

        .post-content {
          padding: 8px 16px;
          width: 100%;

          .text {
            display: inline-block;
          }

          .text.mini {
            max-height: 75px;
            width: 100%;
          }

          .learn-more-btn {
            cursor: pointer;
            color: $blue-piv;
            font-size: 9pt;
            margin: 8px 0;
            border: none;
            display: block;
          }
        }
      }
    }

    .post-comments-section {
      background-color: #fff;

      .post-comments {
        @extend %animate-element;
      }

      .post-comments-unit {
        margin-bottom: 16px;
      }

      .post-comment-header {
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        padding: 16px;
        border-style: solid;
        border-color: $gris-clair $gris-clair $gris-clair $blue-piv;
        border-width: 2px 0 1px;
        color: $blue-piv;
        cursor: pointer;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          background-color: $blue-piv;
          width: 6px;
          height: 100%;
          bottom: 0;
          left: 0;
        }
      }

      .post-comment-footer {
        background-color: #fff;
        border-top: 0;
        padding: 16px;
        cursor: pointer;
        display: flex;
        gap: 8px;
        color: $blue-fonce;
      }

      .post-comment-unit-header {
        padding: 8px 16px;
        display: flex;
        justify-content: space-between;
        font-size: 9pt;

        .post-avatar {
          width: 40px;
          height: 40px;
        }

        .post-header-unit-top {
          display: flex;
          align-items: center;
          gap: 16px;

          .comment-auth-name {
            font-size: 1rem;
            font-weight: bold;
          }
        }

        .post-comment-controllers {
          cursor: pointer;

          img[data-id] {
            padding: 8px;
          }
        }
      }

      .post-comments-unit-cont {
        margin: 8px 16px;
        font-size: 1rem;
        font-family: "Open Sans", sans-serif;
      }

      .response-section {
        background-color: white;
        border-top: 2px solid $gris-clair;

        .response-section-controllers {
          display: flex;
          justify-content: center;
          padding: 30px 0;
        }

        .textarea {
          border: none;
          padding: 16px;
          font-size: 16px;
          font-family: Roboto, sans-serif;
          resize: none;
          height: 50px;
          color: $blue-fonce;
          overflow-y: auto;
          background-color: #fff;
          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none; /* IE and Edge */
          &::-webkit-scrollbar {
            display: none; /* Chrome and Safari */
          }
        }

        .blue-line {
          height: 3px;
          background-color: $blue;
        }

        .btn {
          padding: 16px 24px;
          font-weight: 500;
          margin: 0;
        }
      }
    }
  }

  .pr-row-right-nav {
    display: none;
  }

  @media (max-width: 768px) {
    .container {
      max-width: none;
    }

    .pr-row {
      display: block;
      left: 0;
      padding: 16px;
      overflow-x: hidden;
    }

    .pr-row-left {
      flex: 100%;
      border: 0;
      transform: translate(0);
      position: fixed;
      overflow-y: scroll;
      width: 100%;
      height: 100%;
      left: 0;
      top: 72px;
      padding: 8px 16px 100px;
      background-color: white;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .pr-tabs-content {
        .pr-tab-cont {
          padding-right: 0;
        }
      }
    }

    .pr-row-right {
      flex: 100%;
      border: 0;
      left: 0;
      padding: 0 0 100px;
      position: fixed;
      overflow-y: scroll;
      width: 100%;
      height: 100%;
      transform: translate(100%);
      transition: visibility 0.4s ease, -webkit-transform 0.4s ease, transform 0.4s ease;
      background-color: white;
      top: 84px;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .pr-row-right-nav {
      display: flex;
      width: 100%;
      background-color: white;
      height: 50px;
      padding: 8px 16px;
      align-items: center;
      gap: 16px;
      font-weight: 500;
      margin-top: -16px;
      box-shadow: rgba(0 0 0 / 1%) 0 1px 3px 0, rgba(0 0 0 / 6%) 0 1px 2px 0;
      color: $blue-piv;

      .icon-arrow {
        width: 20px;
      }
    }

    .posts-section {
      .post-unit {
        gap: 8px;

        .post-avatar-col {
          .post-avatar {
            width: 30px;
            height: 30px;
          }
        }
      }

      .post-comments-section {
        .post-comment-unit-header {
          .post-header-unit-top {
            .comment-auth-name {
              gap: 0;
              flex-direction: column;
            }
          }
        }
      }
    }

    .custom-date-display {
      display: flex;
      font-size: 14px;
      color: $gris;
      border-bottom: 1px solid $gris-clair;
      justify-content: center;
      margin: 32px 0;

      & > div {
        margin-bottom: -8px;
        background-color: white;
        padding: 0 8px;
      }
    }
  }

  .infinite-scroll-component {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    .custom-date-display {
      display: flex;
      font-size: 14px;
      color: $gris;
      border-bottom: 1px solid $gris-clair;
      justify-content: center;
      margin: 32px 0;

      & > div {
        margin-bottom: -8px;
        background-color: white;
        padding: 0 8px;
      }
    }
  }
}

.time-ago {
  padding-left: 18px;
  font-weight: 300;
  font-size: 14px;
}

.content-css {
  width: 100%;
  color: $blue-fonce;
}

.linkify {
  &__text {
    text-decoration: underline;
  }

  .infinite-scroll-component {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}

.public-room-component {
  &-responses {
    font-size: 14px;
  }

  &-display-more-result {
    font-size: 14px;
  }
}
