.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 400px;
  color: $blue-fonce;

  & .title {
    font-size: 18px;
    font-weight: bold;
    color: #095797;
  }
}
