.side-nav {
  font-family: Roboto, sans-serif;
  padding-right: 32px;

  ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    font-size: 11pt;

    li {
      list-style: none;
      display: flex;
      font-weight: 300;

      a {
        border-bottom: 1px solid $gris-clair;
        color: $blue-piv;
        text-decoration: none;
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 55px;
        padding: 8px;

        strong {
          color: $blue-fonce;
        }
      }

      a:hover,
      &.active {
        background-color: $blue-pale;
      }

      &.active {
        color: $blue-piv;
        font-weight: bold;
      }

      &.menu-sec-title {
        color: $blue-piv;

        a {
          color: $blue-piv;
        }
      }
    }
  }
}

/* CSS pour afficher les icônes en mode mobile */
@media screen and (max-width: 767px) {
  .toggle-icon {
    display: inline-block;
  }

  .toggle-icon.down {
    display: none;
  }

  .side-nav ul {
    background-color: $blue-pale;
  }

  .side-nav ul li a {
    border-bottom: none;
    min-height: 0;
    padding: 8px 13px 8px 8px;
  }
}
