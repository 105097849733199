.no-content-message {
  p {
    color: $blue-fonce;
    font-weight: bold;
    font-size: 11pt;
  }
}

.pg-404 {
  margin-top: 10px;

  .body-container {
    margin: 0;
    padding: 80px 25px 100px;
    min-height: calc(100vh - 323px);
    background-position: top center;
  }

  .content-card {
    box-shadow: rgba(99 99 99 / 20%) 0 2px 8px 0;
    width: 100%;
    max-width: 800px;
    min-height: 250px;
    margin: auto;
    text-align: center;
    padding: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    color: $blue-fonce;

    p {
      margin: 25px 0;
    }
  }

  @media (max-width: $screen-md-min) {
    .body-container {
      padding: 0;
    }

    .content-card {
      box-shadow: none;
      padding: 25px 25px 50px;
      border-radius: 0;
      background: linear-gradient(to bottom, #fff 34%, #fff0 65%, #fff 100%);
    }
  }
}
