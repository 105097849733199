.form-actions {
  color: #eb705a;

  &.sticky {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 15px;
    background: #fff;
    right: 0;
    z-index: 10000;
    box-shadow: 0 -5px 14px -7px rgb(0 0 0 / 75%);

    & input {
      width: 22rem;
      display: block;
      margin: 0 auto;
    }
  }

  #submit-id-submit {
    width: 22rem;
  }
}

.crispy-form-styles {
  color: $blue-fonce;

  select {
    color: $blue-fonce;
  }

  fieldset {
    color: $blue-fonce;

    select {
      color: $blue-fonce;
    }

    input[type="radio"] {
      width: 0;
      margin: 0 24px 0 0;
    }

    input[type="radio"]::after {
      content: "";
      background-color: #fff;
      border: 3px solid #fff;
      border-radius: 23px;
      display: inline-block;
      width: 23px;
      height: 23px;
      position: relative;
      top: -3pt;
      visibility: visible;
      box-shadow: 0 0 0 2px $blue-fonce;

      @media (max-width: 768px) {
        border: 2px solid #fff;
        width: 16px;
        height: 16px;
        border-radius: 16px;
        box-shadow: 0 0 0 1px $blue-fonce;
      }
    }

    input[type="radio"]:checked::after {
      content: "";
      background-color: $blue;
      border: 3px solid #fff;
      border-radius: 23px;
      display: inline-block;
      width: 23px;
      height: 23px;
      position: relative;
      top: -3pt;
      visibility: visible;
      box-shadow: 0 0 0 2px $blue;

      @media (max-width: 768px) {
        border: 2px solid #fff;
        width: 16px;
        height: 16px;
        border-radius: 16px;
        box-shadow: 0 0 0 1px $blue;
      }
    }
  }
}

#nx-form-preference-notifications {
  padding-left: 0;
  padding-right: 0;
  color: $blue-fonce;

  .control-group {
    padding: 12px 0;

    label[for="id_recevoir_bulletin"] {
      font-size: 13pt;
      font-weight: 600;
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 300px;
      margin: 0 0 12px;

      .slider {
        width: 56px;
        max-width: 56px;
        left: auto;
      }
    }

    label.control-label {
      font-size: 13pt;
      font-weight: 600;
      margin: 0 0 12px;
    }

    label.radio {
      font-size: 12pt;
      font-weight: 400;
      display: block;
      padding: 10px 0;

      input[type="radio"] {
        width: 0;
        margin: 0 32px 0 0;
      }

      input[type="radio"]:hover {
        cursor: pointer;
      }

      input[type="radio"]::after {
        content: "";
        background-color: #fff;
        border: 3px solid #fff;
        border-radius: 23px;
        display: inline-block;
        width: 23px;
        height: 23px;
        position: relative;
        top: -3pt;
        visibility: visible;
        box-shadow: 0 0 0 2px $blue-fonce;

        @media (max-width: 768px) {
          border: 2px solid #fff;
          width: 16px;
          height: 16px;
          border-radius: 16px;
          box-shadow: 0 0 0 1px $blue-fonce;
        }
      }

      input[type="radio"]:checked::after {
        content: "";
        background-color: $blue;
        border: 3px solid #fff;
        border-radius: 23px;
        display: inline-block;
        width: 23px;
        height: 23px;
        position: relative;
        top: -3pt;
        visibility: visible;
        box-shadow: 0 0 0 2px $blue;

        @media (max-width: 768px) {
          border: 2px solid #fff;
          width: 16px;
          height: 16px;
          border-radius: 16px;
          box-shadow: 0 0 0 1px $blue;
        }
      }
    }

    label.radio:hover {
      cursor: pointer;
    }

    label[for="id_recevoir_bulletin"] input[type="checkbox"] {
      display: none;
    }
  }

  select {
    color: $blue-fonce;
  }
}
