:root {
  --loading-spinner-color: $blue-fonce;
  --animation-timing: cubic-bezier(0, 1, 1, 0);
  --animation-duration: 600ms;
  --animation-count: infinite;
}

.loading-container {
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  --spacer: calc(2rem * 1 + 2rem); /* [3] */

  position: relative;
  width: calc(2rem * 3 + 1 * 2rem * 2);
  height: 2rem;
}

.loading span {
  position: absolute;
  top: 0;
  width: 2rem;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background: var(--loading-spinner-color);
  animation-timing-function: var(--animation-timing);
}

.loading span:nth-child(1) {
  left: 0;
  animation: scale-up var(--animation-duration) var(--animation-count);
}

.loading span:nth-child(2) {
  left: 0;
  animation: move-right var(--animation-duration) var(--animation-count);
}

.loading span:nth-child(3) {
  left: calc(var(--spacer) * 1);
  animation: move-right var(--animation-duration) var(--animation-count);
}

.loading span:nth-child(4) {
  left: calc(var(--spacer) * 2);
  animation: scale-down var(--animation-duration) var(--animation-count);
}

@keyframes scale-up {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes scale-down {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0);
  }
}

@keyframes move-right {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(var(--spacer), 0);
  }
}

/* Spinner Circle Rotation */
.sp-circle {
  margin: 5px auto;
  border: 3px solid #ddd;
  border-radius: 50%;
  border-top: 3px solid $gris-moyen;
  width: 22px;
  height: 22px;
  animation: spin-topbar 1s linear infinite;
}

@keyframes spin-topbar {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin-topbar {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.page-loading {
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
}
