.project {
  text-align: center;

  &__header {
    &__title {
      & a span {
        color: $blue-fonce;
        font-size: 1.125rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  &__category {
    list-style: none;
    padding: 0;

    & li {
      display: inline;
      color: $blue-piv;
      font-size: 0.875rem;
      font-family: "Open Sans", sans-serif;
      text-decoration: underline;
      margin-right: 8px;
    }
  }

  &__description {
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      height: 24px;
      width: 24px;
      margin-right: 0.5rem;
      object-fit: contain;
    }

    & p {
      margin: 0;
      color: $blue-fonce;
    }
  }

  &__text {
    text-align: left;
    color: $blue-fonce;
  }
}

.popup {
  text-align: center;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #dae6f0;
    width: 160px;
    height: 90px;
    margin: 0 auto;
    position: relative;
    margin-bottom: 30px;
  }

  &__image {
    align-items: center;
    height: 64px;
  }

  &__title {
    color: $blue-fonce;
    font-size: 1.125rem;

    & a span {
      color: $blue-fonce;
    }
  }

  &__category {
    list-style: none;
    text-align: left;
    padding: 0;

    & li {
      display: inline;
      color: $blue-fonce;
      font-size: 0.875rem;
      font-family: "Open Sans", sans-serif;
      text-decoration: underline;
      margin-right: 8px;
    }
  }

  &__desc {
    display: flex;
    text-align: left;
    align-items: center;
    margin-bottom: 15px;
    word-break: break-word;

    & p {
      margin: 0;
      color: $blue-fonce;
    }

    & img {
      margin-right: 0.5rem;
      height: 24px;
      width: 24px;
      object-fit: contain;
    }

    & a {
      color: $blue-piv;
      text-decoration: underline !important;
    }
  }

  .icon-container {
    background-color: transparent;
    border-radius: 0;
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.company {
  display: flex;
  align-items: center;

  &__description {
    text-align: left;

    & h4 {
      color: $blue-fonce !important;
      font-size: 0.9rem;
    }
  }

  .image-container {
    border: 1px solid #dae6f0;
    width: 100px;
    height: 60px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    position: relative;
  }

  .icon-container {
    background-color: transparent;
    border-radius: 0;
    height: 24px;
    width: 24px;
    position: absolute;
    right: 2px;
    top: 2px;

    & img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  &__category {
    list-style: none;
    padding: 0;

    & li {
      color: $blue-piv;
      font-size: 0.875rem;
      font-family: "Open Sans", sans-serif;
      text-decoration: underline;
      width: 100%;
      margin-right: 8px;
    }
  }
}
