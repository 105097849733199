.pn-select {
  &__control {
    border-radius: 0 !important;
    border-color: #808a9d !important;
    box-shadow: none !important;
    min-height: 40px !important;

    &--menu-is-open {
      border-bottom: none !important;
    }
  }

  &__menu {
    margin: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    display: block !important;
    position: relative !important;
    border: 1px solid #808a9d !important;
    border-top: none !important;
    z-index: 2 !important;
  }

  &__menu-list {
    display: block !important;
  }

  &__option {
    display: block !important;
    background-color: #fff !important;

    &:hover {
      background-color: #dae6f0 !important;
    }

    &--is-selected {
      background-color: #fff !important;
      color: $blue-fonce !important;
    }
  }
}
