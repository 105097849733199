.range-container {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 16px;

  & .input-range {
    background-color: #fff;
    border: 1px solid $gris;
    color: $blue-fonce;
    height: 32px;
    width: 40px;
    font-size: 12px;
    text-align: center;
    border-radius: 0;
  }
}

.multi-range-slider * {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.multi-range-slider {
  display: flex;
  position: relative;
  padding: 20px 15px;

  @media (max-width: 767px) {
    padding: 20px 19px;
  }

  flex-direction: column;
  width: 100%;
  -webkit-touch-callout: none; /* iOS Safari */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, */
}

.multi-range-slider .bar {
  display: flex;
}

.multi-range-slider .bar-left {
  width: 25%;
  background-color: $gris-clair;
}

.multi-range-slider .bar-right {
  width: 25%;
  background-color: $gris-clair;
  padding: 4px 0;
}

.multi-range-slider .bar-inner {
  background-color: $blue;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: space-between;
  position: relative;
}

.multi-range-slider .bar-inner-left {
  width: 50%;
}

.multi-range-slider .bar-inner-right {
  width: 50%;
}

.multi-range-slider .thumb {
  background-color: white;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.multi-range-slider .thumb::before {
  content: "";
  background-color: inherit;
  position: absolute;
  width: 24px;
  height: 24px;
  border: 1px solid $gris;
  border-radius: 50%;
  z-index: 1;
  margin: -8px -12px;
  cursor: pointer;

  @media (max-width: 767px) {
    width: 32px;
    height: 32px;
    margin: -12px -16px;
  }
}

.multi-range-slider .input-type-range:focus + .thumb::after {
  content: "";
  position: absolute;
  top: -4px;
  left: -7px;
  width: 11px;
  height: 11px;
  z-index: 2;
  border-radius: 50%;
  border: dotted 1px $blue-fonce;
  box-shadow: 0 0 5px white, inset 0 0 10px $blue-fonce;
}

.multi-range-slider .caption {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
}

.multi-range-slider .thumb .caption * {
  position: absolute;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
  border-radius: 15px;
  color: $blue-fonce;
  padding: 0 5px;
  top: 160%;
  white-space: nowrap;

  @media (max-width: 767px) {
    top: 150%;
  }
}

// .multi-range-slider .thumb .caption .max-caption {
//   left: -13px;
// }

// .multi-range-slider .thumb .caption .min-caption {
//   left: -22px;
// }

.multi-range-slider .thumb:active .caption {
  display: flex;
}

.multi-range-slider .input-type-range:focus + .thumb .caption {
  display: flex;
}

.multi-range-slider .input-type-range {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  pointer-events: none;
}

.multi-range-slider .ruler {
  display: none;
}

.multi-range-slider .ruler .ruler-rule {
  border-left: solid 1px;
  border-bottom: solid 1px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 5px 0;
}

.multi-range-slider .ruler .ruler-rule:last-child {
  border-right: solid 1px;
}

.multi-range-slider .ruler .ruler-sub-rule {
  border-left: solid 1px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 3px 0;
  bottom: 0;
  margin-bottom: -5px;
}

.multi-range-slider .ruler .ruler-sub-rule:first-child {
  border-left: none;
}

.multi-range-slider .labels {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-top: 10px;
  margin-bottom: -20px;

  /* display: none; */
}

.multi-range-slider .label {
  font-size: 80%;
  display: flex;
  width: 1px;
  justify-content: center;
}

.multi-range-slider .label:first-child {
  justify-content: start;
}

.multi-range-slider .label:last-child {
  justify-content: end;
}

.multi-range-slider.zero-ranage-margin .thumb-left {
  right: 12px;
}

.multi-range-slider.zero-ranage-margin .thumb-right {
  left: 8px;
}
