*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus {
  outline: none !important;
}

html {
  font-size: 100%;
  color: $blue-fonce;

  @media (max-width: 1000px) {
    font-size: 90%;
  }

  @media (max-width: 890px) {
    font-size: 80%;
  }
}

body {
  background: white;
  font-family: "Open Sans", sans-serif;
  height: 100%;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  background: #fff;
}

body::-webkit-scrollbar-thumb {
  opacity: 1;
}

img {
  max-width: 100%;
}

.height-100p {
  height: 100%;
}

/** start: accordion **/
.accordion-item {
  border-radius: 0 !important;
}

.accordion-button:not(.collapsed) {
  background-color: $gris-pale;
  color: $blue-fonce;
}

.accordion-button:focus {
  border-color: transparent;
  outline: none !important;
}

.accordion-item:first-of-type .accordion-button {
  border-radius: 0 !important;
}

.accordion-button[aria-expanded="false"]::after {
  content: "+";
  background-image: none;
  text-align: right;
  font-size: 2rem;
  margin-top: 0;
  position: absolute;
  right: 10px;
  top: 5px;
}

.accordion-button[aria-expanded="true"]:not(.collapsed)::after {
  content: "-";
  background-image: none;
  font-size: 2rem;
  margin-top: 0;
  position: absolute;
  right: 10px;
  top: 25px;
}

input,
textarea,
select {
  outline: none;
}

h1,
h2,
h3 {
  color: $blue-fonce;
  font-family: $font-family-roboto;
}

h1 {
  font-size: 48px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-top: 72px;

  &::after {
    content: " ";
    display: inline-block;
    width: 50px;
    height: 5px;
    background-color: $rose;
  }
}

p {
  &.with-icon {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  strong {
    color: $blue-fonce;
  }

  &.md {
    font-size: 14pt;
  }

  &.lg {
    font-size: 16pt;
  }
}

a {
  color: $blue-piv;
  text-decoration: none;
}

.box-shadow {
  box-shadow: rgb(0 0 0 / 4%) 0 3px 5px;
}

.txt-right {
  text-align: right;
}

.txt-left {
  text-align: left;
}

.search-bar {
  width: 100%;
  display: flex;

  input {
    width: 89%;
    border: 1px solid $gris;
    padding: 7px;
  }

  button {
    background-color: $blue-piv;
    border: 0;
    border-radius: 0;
    padding: 8px;
    width: 40px;
  }
}

.input-area {
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  gap: 5px;

  .checkmark {
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid $gris;

    &::after {
      content: "";
      position: absolute;
      display: none;
      left: 5px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $blue-piv;

      &::after {
        display: block;
      }
    }
  }

  &[disabled] {
    opacity: 0.6;
  }
}

.address-box-wrapper {
  margin-bottom: 40px;

  .i-row {
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.address-box {
  padding: 20px 16px;
  border: 1px solid $gris-clair;
  max-width: 400px;
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
  color: $blue-fonce;
  word-break: break-word;

  .i-row {
    display: flex;
    gap: 8px;

    p {
      margin: 0;
    }

    .address-box-telephone {
      color: $blue-fonce;
    }
  }
}

.address-box-under {
  display: flex;
  gap: 16px;
  margin: 16px 0;
  justify-content: flex-start;
  max-width: 400px;

  & button {
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0;

    & .icon {
      margin-right: 5px;
    }
  }
}

select {
  border: 1px solid $gris-moyen;
  padding: 8px;
}

table.center {
  text-align: center;
}

.paginator {
  ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;

      a {
        width: 40px;
        height: 40px;
        font-size: 1rem;
        font-weight: bold;
        background-color: white;
        color: $blue-moyen;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
      }

      &.active a {
        background-color: $blue-moyen;
        color: white;
      }

      &:hover a {
        background-color: $gris-pale;
        color: $blue-moyen;
      }

      .next {
        background-color: $gris-clair;
        color: white;

        &:hover {
          background-color: $gris-clair;
        }
      }

      .prev {
        color: $gris-clair;
      }
    }
  }
}

.avis-general {
  border: 1px solid $alert-blue;
  display: flex;

  div:first-child {
    width: 40px;
    background-color: $gris-clair;
    padding: 16px 8px;
    margin-top: -1px;
    margin-bottom: -1px;
  }

  div:last-child {
    padding: 16px 24px 24px;
    font-size: 14px;
    color: $blue-fonce;

    ul {
      margin: 0;
      padding: 0 0 0 16px;

      a {
        color: $rouge;
      }
    }

    strong {
      margin-top: -6px;
      display: block;
    }
  }

  &.blue > div:first-child {
    background-color: $alert-blue;
  }

  &.red > div:first-child {
    background-color: $alert-pink;
  }

  &.green > div:first-child {
    background-color: $vert-pale;
  }

  &.yellow > div:first-child {
    background-color: $alert-yellow;
  }
}

%popup-note {
  display: flex;
  background-color: $gris;
  color: white;
  width: 344px;
  padding: 16px;
  gap: 8px;
  box-shadow: rgba(99 99 99 / 20%) 0 2px 8px 0;
  position: fixed;
  right: 32px;
  bottom: 32px;
  z-index: 100;

  img {
    width: 24px;
    cursor: pointer;
  }

  &.green {
    background-color: $vert;
  }

  &.red {
    background-color: $alert-red;
  }

  &.blue {
    background-color: $blue-piv;
  }
}

.popup-note {
  @extend %popup-note;
}

.popup-note-demo {
  @extend %popup-note;

  position: initial;
  display: flex;
}

.display-popup-note {
  .display-link {
    cursor: pointer;
  }

  .popup-note {
    display: none;
  }
}

.thumbs-section {
  .margin-bottom {
    margin-bottom: 48px;
    padding-left: 27px;
    padding-right: 27px;
  }

  .card-date {
    font-size: 0.917rem;
    color: $blue-fonce;
    margin-top: 16px;
  }

  .card-title {
    font-size: 1.5rem;
    color: $blue-fonce;
    font-weight: bold;
    font-family: Roboto, "Open sans", sans-serif;

    .card-title-link {
      color: $blue-fonce;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .card-company-name {
    font-size: 19px;
    color: $blue-fonce;
    font-weight: bold;
    font-family: Roboto, "Open sans", sans-serif;
    margin-top: 16px;
    text-align: left;
  }

  .buttons-section {
    list-style: none;
    padding: 0;
    width: 100%;
    margin: 24px 0;

    li {
      display: inline-block;

      .link {
        font-size: 9pt;
        color: white;
        text-decoration: none;
        background-color: $blue-clair;
        border-radius: 8px;
        padding: 8px;
        font-weight: 500;
      }
    }
  }

  .card-links-list {
    text-transform: uppercase;
    margin: 16px 0;
    padding: 0;
    list-style: none;

    li {
      .link {
        font-size: 0.917rem;
        text-decoration: underline;
        color: $blue-fonce;
      }
    }
  }

  .card-extra-info {
    list-style: none;
    padding: 0;
    width: 100%;

    li {
      display: inline-flex;
      gap: 8px;

      .link {
        color: $blue-piv;
        display: inline-flex;
        font-size: 12pt;
        gap: 8px;
        text-decoration: none;
        font-weight: 500;
      }
    }

    li:not(:last-child) {
      margin-right: 8px;
    }
  }

  .card-tags {
    padding: 0;
    list-style: none;
    margin: 16px 0;

    .tag {
      a {
        text-decoration: none;
        background-color: $blue-pale;
        font-size: 9pt;
        color: $blue-piv;
        padding: 8px;
        font-weight: 500;
      }
    }

    .tag2 {
      display: inline-block;
      margin: 8px 2px;

      a {
        font-size: 14px;
        color: $blue-fonce;
        font-weight: 500;
        padding: 8px;
        border-radius: 4px;
        border: 1px solid $blue-fonce;
        text-decoration: none;
      }
    }
  }

  .card-address {
    font-size: 12pt;
    display: flex;
    gap: 8px;
    align-items: flex-start;
    color: $blue-fonce;
    margin-top: 11.12px;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .i-cont {
    font-size: 12pt;
    margin: 16px 0;
    color: $blue-fonce;
  }

  .card-wrap {
    box-shadow: rgba(99 99 99 / 20%) 0 2px 8px 0;
    padding: 16px;
  }
}

.alert-message {
  padding: 16px;
  display: none;

  .content {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;

    p {
      margin: 0;
    }

    .c-center {
      display: flex;
      align-items: center;
      gap: 24px;

      &.nx-fstart {
        align-items: flex-start;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        a {
          color: white;
        }
      }
    }
  }

  .close {
    cursor: pointer;
  }

  &[data-status="active"] {
    display: block;
  }

  &[data-color="red"] {
    background-color: $alert-red;
    color: white;
  }

  &[data-color="yellow"] {
    background-color: $alert-yellow;
    color: $blue-fonce;
  }

  &[data-color="blue"] {
    background-color: $alert-blue;
    color: $blue-fonce;
  }

  &[data-color="green"] {
    background-color: #5b7f45;
    color: #fff;
  }

  &.nx-userprofile-alert-message {
    background-color: #fff;
    border: 1px solid $gris-clair;
    color: $blue-fonce;
    margin: -32px 0 32px;

    + .nx-userprofile-alert-message {
      margin-top: -24px;
    }

    &[data-color="green"] {
      background: linear-gradient(to right, #d7f0bb 54px, #fff 54px);
    }

    &[data-color="red"] {
      background: linear-gradient(to right, #f8e69a 54px, #fff 54px);
    }

    &[data-color="yellow"] {
      background: linear-gradient(to right, #dae6f0 54px, #fff 54px);
    }

    .nx-userprofile-alert-message-container {
      margin-bottom: 0;

      .content {
        .close {
          margin: 0 0 1px;
        }
      }
    }

    .nx-userprofile-alert-message-icon {
      width: 24px;
    }
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 90px;
  right: 30px;
  z-index: 99;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-color: $blue-piv;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px rgb(34 54 84 / 29%);
  display: none;

  &.show {
    display: flex;
  }
}

.under-filter-info {
  border-bottom: 1px solid $blue-fonce;
  display: flex;
  color: $blue-fonce;
  font-weight: normal;
  padding-bottom: 8px;
  margin: 32px 0 0;
  justify-content: space-between;
}

.text-dark-blue {
  color: $blue-fonce;
}

.container {
  padding: 0;
}

.row {
  width: 100%;
  margin: 0;
}

@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: $screen-md-min) {
  h1 {
    font-size: 20pt;
  }

  input[type="checkbox"] {
    height: 1rem;
    width: 1rem;
  }
}

// generale style for checkbox

input[type="checkbox"] {
  /* Remove most all native input styles */
  appearance: none;

  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentcolor;
  height: 1rem !important;
  width: 1rem !important;
  border: 1.5px solid $blue-fonce;
  border-radius: 0;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 11.5px;
  height: 9.5px;
  clip-path: polygon(24.6% 64.39%, 7.4% 64.39%, 46.5% 101%, 95% 22%, 82.15% 24.5%, 43.7% 82.45%);
  transform-origin: top top top top;
  border: 1.5px solid transparent;

  /* Windows High Contrast Mode */
  background-color: "canvastext";
}

input[type="checkbox"]:checked::before {
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -59%);
}

input[type="checkbox"]:checked {
  border: none;
  background-color: $blue;
  border-radius: 0;
}

.document-page-tag {
  font-size: 0.875rem;
  padding: 10px 16px;
  color: $blue-piv;
  background-color: $blue-pale;
  font-weight: 500;
  margin-top: 0.5rem;
  width: fit-content;
}

.header-underline {
  border-bottom: 1px solid $blue-fonce;
  padding-bottom: 25px;
  color: $blue-fonce;
}

.homepage-padding {
  padding: 0 8px;

  @media (max-width: 768px) {
    padding: 0 16px;
  }
}

.flex-page-padding {
  padding: 0 20px;

  @media (max-width: 768px) {
    padding: 0 24px;
  }
}

.profile-page-padding {
  padding: 0 20px;

  @media (max-width: 768px) {
    padding: 0 16px;
  }
}

.font-size-40px {
  font-size: 2.5rem;
}

.margin-bottom-56px {
  margin-bottom: 56px;
}

.padding-sm-8px {
  @media (max-width: 768px) {
    padding: 0 8px;
  }
}

.mt-btn-24px {
  margin-top: 24px;
}
