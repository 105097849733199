.footer {
  background-color: $blue-fonce;
  font-size: 12pt;
  font-family: Roboto, sans-serif;

  &__navigation {
    background-color: $blue-fonce;
    padding: 40px 0;
    margin-top: 80px;

    h5 {
      color: #fff;
      width: 250px;
      font-size: 1rem;
      font-weight: 700;
    }

    h6 {
      font-size: 1.25rem;
      color: #fff;
      font-weight: bold;
    }

    .extern-link {
      color: #fff;
      font-size: 0.875rem;
    }

    .contact-email {
      font-size: 0.875rem;
      cursor: pointer;
      text-decoration: none;
      color: #fff;
    }
  }

  &__list {
    list-style: none;
    padding: 0;

    & li {
      padding: 5px 0;

      & a {
        color: #fff;
        font-size: 0.875rem;
        font-weight: 300;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__white {
    .f-container {
      color: $blue-piv;
      max-width: 1200px;
      padding: 18px 15px 0;
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      flex-direction: column;
      gap: 25px;

      ul {
        padding: 0;
        margin: 0;
        display: flex;
        gap: 25px;

        li {
          list-style: none;
          display: inline-block;

          a {
            color: $blue-piv;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .f-container {
      font-size: 9pt !important;

      ul {
        flex-direction: column;
      }

      .logo {
        max-width: 80%;
      }
    }
  }
}

/* social network icons */

.social-network {
  display: flex;
  gap: 10px;
  margin-top: 10px;

  & div {
    background: #fff;
    padding: 3px;
  }
}
