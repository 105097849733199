.pg-profile {
  .page-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 15px 30px;

    h1 {
      margin-bottom: 32px;
    }

    @media (max-width: 767px) {
      margin: 0;
      margin-bottom: 32px;
    }
  }

  #user-info-edit {
    display: none;
  }

  .btn-edit-user-info-wrapper {
    float: right;
  }

  @media (max-width: 767px) {
    .btn-edit-user-info-wrapper {
      float: initial;
      text-align: center;
      margin-bottom: 25px;
    }
  }

  .crispy-form {
    .control-group {
      margin: 0 0 28px;

      .control-label {
        font-weight: 600;
        display: block;
        margin: 0 0 6px;
      }

      .controls {
        position: relative;

        .nx-datepicker,
        .dateinput {
          position: relative;

          .customdatepickerinput {
            &:focus,
            &:focus-within,
            &:active {
              border: 1px solid $blue-clair;
              outline: 1px solid $blue-clair !important;
              box-shadow: none;
            }
          }

          &::after {
            content: "";
            background-color: #fff;
            background-image: url("../../vendors/images/icons/calendar-empty-b.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 20px;
            height: 20px;
            position: absolute;
            right: 12px;
            bottom: 12px;
          }
        }

        .dateinput {
          position: relative;

          &:focus,
          &:focus-within,
          &:active {
            border: 1px solid $blue-clair;
            outline: 1px solid $blue-clair !important;
            box-shadow: none;
          }

          &::after {
            content: "";
            background-color: #fff;
            background-image: url("../../vendors/images/icons/calendar-empty-b.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 20px;
            height: 20px;
            position: absolute;
            right: 12px;
            bottom: 12px;
          }
        }

        input[type="text"],
        input[type="tel"],
        input[type="email"] {
          font-weight: 400;

          &.regionalphonenumberwidget {
            padding-left: 40px;
            color: $blue-fonce;
          }
        }

        &:has(input.regionalphonenumberwidget)::before {
          content: "";
          background-color: transparent;
          background-image: url("/vendors/images/icons/phone-blue.svg");
          background-repeat: no-repeat;
          background-size: cover;
          display: inline-block;
          width: 18px;
          height: 18px;
          position: absolute;
          top: 12px;
          left: 10px;
        }
      }

      &.error {
        .controls {
          &:has(input.regionalphonenumberwidget)::before {
            top: 14px;
          }
        }
      }
    }

    .form-footer {
      .nx-btn-submit-save {
        font-size: 16px;
        font-weight: 400;
        line-height: 120%;
        padding: 18px 12px;
        margin: 12px 0 0;
      }
    }

    .control-group[id="div_id_logo"],
    .control-group[id="div_id_cover"] {
      div.controls {
        border-bottom: 1px solid $gris-clair;
        font-size: 0;
        line-height: 0;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 0 0 16px;
        margin: 16px 0;

        /* new */
        &::before {
          content: "";
          background-image: url("../../vendors/images/icons/download-b.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          display: inline-block;
          width: 16px;
          height: 16px;
          position: absolute;
          right: 8px;
          bottom: 27px;
          z-index: 1;
        }

        &::after {
          content: "";
          border-bottom: 1px solid $gris-clair;
          display: block;
          width: 100%;
          height: 1px;
          position: relative;
          top: -51px;
        }

        > a {
          font-size: 14px;
          font-weight: 400;
          line-height: 120%;
          text-decoration: underline;
          text-overflow: ellipsis;
          overflow: hidden;
          flex: 1 1 50%;
          width: 50%;
          padding: 0 12px 0 0;
        }

        input[name="logo-clear"],
        input[name="cover-clear"] {
          display: none;

          + label[for="logo-clear_id"],
          + label[for="cover-clear_id"] {
            text-align: right;
            flex: 0 0 auto;
            width: auto;
            min-width: 120px;
            padding: 0;
            margin: 0;

            &::after {
              content: "Supprimer";
              color: $blue-piv;
              font-size: 14px;
              line-height: 120%;
              display: inline-block;
              cursor: pointer;
              padding: 0;
              margin: 0;
            }
          }

          &:checked {
            + label[for="logo-clear_id"],
            + label[for="cover-clear_id"] {
              &::after {
                content: "Annuler";
                color: #f00;
              }
            }
          }
        }

        input[name="logo"],
        input[name="cover"] {
          height: 35px;
          margin: 32px 0 0;
        }

        .nx-widgets-clearablefileinput {
          width: 100%;
          cursor: pointer;

          input[name="id_logo"],
          input[name="id_cover"] {
            &:not(.nx-widgets-clearablefileinput-hasvalue)::after {
              content: ".jpg, .png";
              background-color: #fff;
              color: #aaa;
              font-size: 14px;
              font-weight: 400;
              line-height: 33px;
              display: inline-block;
              width: calc(100% - 2px);
              height: 32px;
              position: absolute;
              left: 1px;
              bottom: 18px;
              padding: 0 12px;
            }

            color: $blue-piv;
            font-size: 14px;
            font-weight: 400;
            line-height: 30px;
            flex: 1 1 100%;
            height: 35px;
            cursor: pointer;
            padding: 2px;
            margin: 32px 0 0;

            &::file-selector-button {
              background-color: transparent;
              border: none;
              border-radius: 0;
              color: transparent;
              font-size: 0;
              padding: 0;
            }

            &::file-selector-button.oldcode {
              background-color: #8793a2;
              border: none;
              border-radius: 0;
              color: #fff;
              font-size: 14px;
              font-weight: 400;
              line-height: 120%;
              cursor: pointer;
              padding: 6px 16px;
            }
          }

          &:not(.nx-widgets-clearablefileinput-hasvalue) {
            input[name="logo"],
            input[name="cover"] {
              font-size: 0;
              line-height: 0;
            }
          }
        }
      }
    }

    /* stylelint-disable */
    .control-group[id="div_id_documents_corporatifs"] {
      &::after {
        content: "";
        border-bottom: 1px solid #c5cad2;
        display: block;
        width: 100%;
        height: 1px;
        position: relative;
        top: -69px;
      }
      div.controls {
        border-bottom: 1px solid $gris-clair;
        font-size: 0;
        line-height: 0;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 0 0 16px;

        /* new */
        &::before {
          content: "";
          background-image: url("../../vendors/images/icons/download-b.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          display: inline-block;
          width: 16px;
          height: 16px;
          position: absolute;
          right: 8px;
          bottom: 27px;
          z-index: 1;
        }

        > a {
          font-size: 14px;
          font-weight: 400;
          line-height: 120%;
          text-decoration: underline;
          text-overflow: ellipsis;
          overflow: hidden;
          flex: 1 1 50%;
          width: 50%;
          padding: 0 12px 0 0;
        }

        input[name="documents_corporatifs-clear"] {
          display: none;
          + label[for="documents_corporatifs-clear_id"] {
            text-align: right;
            flex: 0 0 auto;
            width: auto;
            min-width: 120px;
            padding: 0;
            margin: 0;

            &::after {
              content: "Supprimer";
              color: $blue-piv;
              font-size: 14px;
              line-height: 120%;
              display: inline-block;
              cursor: pointer;
              padding: 0;
              margin: 0;
            }
          }
          &:checked {
            + label[for="documents_corporatifs-clear_id"] {
              &::after {
                content: "Annuler";
                color: #f00;
              }
            }
          }
        }

        .nx-widgets-clearablefileinput {
          width: 100%;
          cursor: pointer;

          &:not(.nx-widgets-clearablefileinput-hasvalue) {
            input[name="documents_corporatifs"] {
              font-size: 0;
              line-height: 0;
            }
          }
        }
      }

      input[name="documents_corporatifs"] {
        &:not(.nx-widgets-clearablefileinput-hasvalue)::after {
          content: ".pdf, .jpg, .png, .word, .excel";
          background-color: #fff;
          color: #aaa;
          font-size: 14px;
          font-weight: 400;
          line-height: 33px;
          display: inline-block;
          width: calc(100% - 2px);
          height: 32px;
          position: absolute;
          left: 1px;
          bottom: 18px;
          padding: 0 12px;
        }

        color: $blue-piv;
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
        flex: 1 1 100%;
        height: 35px;
        cursor: pointer;
        padding: 2px;
        margin: 32px 0 0;

        &::file-selector-button {
          background-color: transparent;
          border: none;
          border-radius: 0;
          color: transparent;
          font-size: 0;
          padding: 0;
        }

        &::file-selector-button.oldcode {
          background-color: #8793a2;
          border: none;
          border-radius: 0;
          color: #fff;
          font-size: 14px;
          font-weight: 400;
          line-height: 120%;
          cursor: pointer;
          padding: 6px 16px;
        }
      }
    }
    /* stylelint-enable */
    .nx-add-documents-item {
      border-bottom: 1px solid $gris-clair;

      // display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding: 0 0 1rem;
      margin: 1rem 0;

      .nx-add-documents-item-link {
        font-size: 14px;
        font-weight: 400;
        line-height: 120%;
        text-decoration: underline;
        padding: 0;
      }

      > .control-group[id$="-file"] {
        display: none;
      }

      .control-group {
        padding: 0;
        margin: 0;

        .controls {
          label {
            background-color: transparent;
            border: none;
            color: $blue-piv;
            font-size: 14px;

            // font-weight: 600;
            line-height: 120%;
            margin: 0;

            &:hover {
              color: var(--bs-link-hover-color);
              cursor: pointer;
            }

            &:has(input:checked) {
              font-size: 0;
              line-height: 0;

              &::after {
                content: "Annuler";
                color: $rouge;
                font-size: 14px;
                line-height: 120%;
                display: inline-block;
                cursor: pointer;
              }
            }

            input[type="checkbox"] {
              display: none;
            }
          }
        }
      }

      > button.delete-button {
        background-color: transparent;
        border: none;
        color: $blue-piv;
        font-size: 14px;
        font-weight: 600;
        line-height: 120%;
        order: 2;
      }
    }

    .nx-add-documents-item-new {
      border-bottom: 1px solid $gris-clair;

      // display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding: 0 0 1rem;
      margin: 3rem 0;

      .control-group {
        padding: 0 6px 0 0;
        margin: 0;

        // .control-label {
        //   display: none;
        // }

        .controls {
          input[type="file"] {
            color: $blue-piv;
            font-size: 14px;
            font-weight: 400;
            line-height: 30px;
            flex: 1 1 100%;
            height: 35px;
            cursor: pointer;
            padding: 2px;

            &::file-selector-button {
              background-color: transparent;
              border: none;
              border-radius: 0;
              color: transparent;
              font-size: 0;
              padding: 0;
            }

            &::file-selector-button.oldcode {
              background-color: #8793a2;
              border: none;
              border-radius: 0;
              color: #fff;
              font-size: 14px;
              font-weight: 400;
              line-height: 120%;
              cursor: pointer;
              padding: 6px 16px;
            }

            &:not(.nx-widgets-clearablefileinput-hasvalue)::before {
              content: "";
              background-image: url("../../vendors/images/icons/download-b.svg");
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;
              display: inline-block;
              width: 16px;
              height: 16px;
              position: absolute;
              right: 8px;
              bottom: 11px;
              z-index: 1;
            }

            &:not(.nx-widgets-clearablefileinput-hasvalue)::after {
              content: ".pdf, .jpg, .png, .word, .excel";
              background-color: #fff;
              color: #aaa;
              font-size: 14px;
              font-weight: 400;
              line-height: 29px;
              display: inline-block;
              width: calc(100% - 2px);
              height: 32px;
              position: absolute;
              left: 1px;
              bottom: 2px;
              padding: 0 12px;
            }
          }
        }
      }

      button.delete-button {
        background-color: transparent;
        border: none;
        color: $blue-piv;
        font-size: 14px;
        font-weight: 600;
        line-height: 120%;
        padding: 0;
        margin: 0;
      }
    }
  }
}

html[lang^="en"] {
  .pg-profile {
    .crispy-form {
      .nx-add-documents-item {
        .control-group {
          .controls {
            label {
              &:has(input:checked) {
                &::after {
                  content: "Cancel";
                }
              }
            }
          }
        }
      }
    }
  }
}

body .datepicker.datepicker-dropdown {
  border-radius: 0;
  border: 2px solid $blue-clair;
  border-top: none;
  transform: translate(-1px, -1px);
  padding: 8px 0;

  &::before,
  &::after {
    display: none;
  }

  table {
    thead {
      tr {
        th {
          border: 16px solid transparent;
          color: $blue-fonce;
          font-size: 14px;
          line-height: 20px;
        }

        th.dow {
          color: $blue-fonce;
          font-size: 0;
          line-height: 0;
          text-transform: uppercase;

          &::first-letter {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          width: 38px;
          height: 38px;

          span.active {
            background-image: none;
            background-color: $blue;
            border-radius: 0;
            color: #fff;
          }
        }

        td.day {
          border: 16px solid transparent;
          border-radius: 0;
          color: $blue-fonce;
          font-size: 14px;
          line-height: 20px;

          &.old {
            color: $gris-moyen;
          }

          &.active {
            background-image: none;
            background-color: $blue;
            color: #fff;
          }
        }
      }
    }
  }
}

/* stylelint-disable */
// style for input type file
  #company_files_formset {
    & .controls > input[type="file"] {
      position: relative;
      &::before {
        content: "";
        background-image: url("../../vendors/images/icons/download-b.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        display: inline-block;
        width: 16px;
        height: 16px;
        position: absolute;
        right: 8px;
        bottom: 50%;
        transform: translateY(35%);
        z-index: 1;
      }
    }
  & input[type="file"] {
      cursor: pointer;
      &:not(.nx-widgets-clearablefileinput-hasvalue):after {
        content: ".jpg, .png, .pdf, .docs, .excel, .csv";
        cursor: pointer;
        background-color: #fff;
        color: #aaa;
        font-size: 14px;
        font-weight: 400;
        line-height: 33px;
        display: inline-block;
        width: calc(100% - 2px);
        height: 32px;
        position: absolute;
        left: 1px;
        bottom: 5px;
        padding: 0 12px;
      }
      
      &::file-selector-button {
        background-color: transparent;
        border: none;
        border-radius: 0;
        color: transparent;
        font-size: 0;
        padding: 0;
      }

      &::file-selector-button.oldcode {
        background-color: #8793a2;
        border: none;
        border-radius: 0;
        color: #fff;
        font-size: 14px !important;
        font-weight: 400;
        line-height: 120%;
        cursor: pointer;
        padding: 6px 16px;
        z-index: 99999999;
      }
    }

    & input[type="checkbox"] {
      display: none;
    }

    & .controls a {
    display: block;
    }
    & .controls label {
      display: none;
    }
  }
