:root {
  --timeline-col-width: calc(100% / 12);
}

.timeline-year-view {
  margin-bottom: 50px;
  overflow-x: scroll;
  cursor: all-scroll;

  &::-webkit-scrollbar {
    height: 4px;              /* height of horizontal scrollbar ← You're missing this */
    width: 4px;               /* width of vertical scrollbar */
    border: 1px solid #d5d5d5;
  }

  ::-webkit-scrollbar-thumb {
    background: red;
  }

  .timeline-months {
    &.t-header {
      display: flex;
      font-size: 9pt;
      color: $blue-moyen;
      text-align: center;

      .t-col {
        width: var(--timeline-col-width);
      }
    }

    &.t-body-bg {
      background: linear-gradient(90deg, transparent calc(100% - 1px), #c5cad2 0);
      background-size: calc((100% - 60px) / 12 + 5px) 100%;
      border-left: 1px solid #c5cad2;
    }

    &.t-body {
      min-height: 500px;
      padding-top: 16px;

      .info-card {
        min-height: 52px;
        border-left: 10px solid $blue;
        max-width: initial;
        display: inline-flex;
        margin-bottom: 16px;
        background-color: white;
        box-shadow: rgba(99 99 99 / 20%) 0 2px 8px 0;
        min-width: 300px;
        padding: 8px;
        flex-direction: column;

        /* min-width: 300px; **/

        &:hover {
          box-shadow: rgba(50 50 93 / 25%) 0 13px 27px -5px, rgba(0 0 0 / 30%) 0 8px 16px -8px;
          cursor: pointer;
        }

        .info-card-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 16px;
          width: 100%;

          .card-title {
            font-size: 14pt;
            color: $blue-piv;
            font-weight: bold;
          }

          img {
            width: 20px;
          }
        }

        .info-card-body {
          max-width: 400px;
          padding-top: 15px;
          display: none;

          .title-link {
            font-size: 12pt;
            color: $gris-fonce;
          }

          .doc-link {
            display: flex;
            gap: 8px;
            margin-top: 8px;
          }
        }
      }

      .month-1 {
        margin-left: calc(var(--timeline-col-width) * 0);
        max-width: calc(var(--timeline-col-width) * 12);
      }

      .month-2 {
        margin-left: calc(var(--timeline-col-width) * 1);
        max-width: calc(var(--timeline-col-width) * 11);
      }

      .month-3 {
        margin-left: calc(var(--timeline-col-width) * 2);
        max-width: calc(var(--timeline-col-width) * 10);
      }

      .month-4 {
        margin-left: calc(var(--timeline-col-width) * 3);
        max-width: calc(var(--timeline-col-width) * 9);
      }

      .month-5 {
        margin-left: calc(var(--timeline-col-width) * 4);
        max-width: calc(var(--timeline-col-width) * 8);
      }

      .month-6 {
        margin-left: calc(var(--timeline-col-width) * 5);
        max-width: calc(var(--timeline-col-width) * 7);
      }

      .month-7 {
        margin-left: calc(var(--timeline-col-width) * 6);
        max-width: calc(var(--timeline-col-width) * 6);
      }

      .month-8 {
        margin-left: calc(var(--timeline-col-width) * 7);
        max-width: calc(var(--timeline-col-width) * 5);
      }

      .month-9 {
        margin-left: calc(var(--timeline-col-width) * 8);
        max-width: calc(var(--timeline-col-width) * 4);
      }

      .month-10 {
        margin-left: calc(var(--timeline-col-width) * 9);
        max-width: calc(var(--timeline-col-width) * 3);
      }

      .month-11 {
        margin-left: calc(var(--timeline-col-width) * 10);
        max-width: calc(var(--timeline-col-width) * 2);
      }

      .month-12 {
        margin-left: calc(var(--timeline-col-width) * 11);
        max-width: calc(var(--timeline-col-width) * 1);
      }

      .t-row {
        display: flex;

        .t-col {
          min-height: 75px;
          width: calc(100% / 12);

          /* border-right: 1px solid #ddd; */
        }

        .t-col:first-child {
          /* border-left: 1px solid #ddd; **/
        }
      }
    }
  }
}
