.members-profile-header {
  height: 357px;
  width: 100%;
  background-position: center center;
  background-size: cover;
  margin-bottom: 120px;

  .avatar {
    background-color: #fff;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border: 16px solid #fff;
    outline: $blue-pale solid 1px;
    box-sizing: content-box;
    width: 340px;
    height: 230px;
    position: relative;
    top: 180px;
    margin-left: 5%;
  }
}

@media (max-width: 410px) {
  .members-profile-header {
    .avatar {
      width: calc(100% - 10%);
      height: auto;
      aspect-ratio: 340/230;
    }
  }
}
