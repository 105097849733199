.table-style {
  display: flex;
  flex-direction: column;
  width: 100%;

  & tr {
    display: flex;
    width: 100%;

    & th {
      padding: 17px 16px;
      color: $blue-fonce;
      display: flex;
      align-items: center;
      width: 100%;
    }

    & td {
      padding: 25px 16px;
      overflow-x: scroll;
      color: $blue-fonce;
      display: flex;
      align-items: center;
      width: 100%;
    }

    & td::-webkit-scrollbar {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .table-container {
    overflow-x: auto;
  }

  .table-style {
    width: 700px;

    & th,
    & td {
      padding: 17px 16px;
      text-overflow: ellipsis;
    }
  }
}
