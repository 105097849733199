.pagination {
  color: $blue-piv;

  & li {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    a:not([href]) {
      font-size: 1rem;
      color: $blue-piv;
    }

    &.previous a {
      color: transparent;
    }

    &.next a {
      color: transparent;
    }

    &.active {
      font-weight: bold;

      & a {
        color: $blue-fonce !important;
      }
    }
  }

  .next {
    position: relative;
    z-index: 999;

    &.disabled {
      cursor: no-drop;

      & a {
        cursor: no-drop;
        color: transparent;
      }
    }

    &::after {
      content: "";
      background-image: url("../../vendors/images/icons/chevron-right.svg");
      background-size: cover;
      height: 50%;
      width: 50%;
      position: absolute;
      transform: translate(-50% -50%);
      z-index: -1;
    }
  }

  .previous {
    position: relative;
    z-index: 999;

    &.disabled {
      cursor: no-drop;

      & a {
        cursor: no-drop;
        color: transparent;
      }
    }

    &::after {
      content: "";
      background-image: url("../../vendors/images/icons/chevron-left.svg");
      background-size: cover;
      height: 50%;
      width: 50%;
      position: absolute;
      transform: translate(-50% -50%);
      z-index: -1;
    }
  }
}
