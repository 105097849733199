%btn-one {
  font-family: "Open Sans", serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  display: inline-block;
  background-color: transparent;
  box-sizing: border-box;
  min-width: 112px;
  padding: 14px 22px;
  transition: all 0.24s ease-in-out;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 0;
  margin-right: 32px;

  &.md {
    font-size: 14pt;

    img {
      width: 15px;
    }
  }

  &.compact {
    padding: 10px 15px;
    width: auto;
  }

  &.forum {
    padding: 10px 15px;
    width: 212px;
    font-weight: 500;
  }

  .icon-w {
    display: none;
  }

  &:hover {
    .icon-w {
      display: inline-block;
    }

    .icon {
      display: none;
    }

    background-color: $blue-piv;
    color: white;
  }

  @media (max-width: $screen-md-min) {
    font-size: 12pt;
  }
}

.btn {
  @extend %btn-one;

  &:disabled {
    opacity: 0.7;
    background-color: initial;
  }

  &.secondary {
    background-color: transparent;
    border: 2px solid $blue-piv;
    color: $blue-piv;

    &:disabled {
      opacity: 0.5;
      border: 2px solid $blue-piv;
    }
  }
}

.btn.btn-primary {
  color: #fff !important;
  border-color: #095797;
  background-color: #095797;
  box-shadow: 0 2px 8px rgb(34 54 84 / 16%);

  &:hover {
    border-color: #156bb2;
    background-color: #156bb2;
  }

  &:focus {
    border-color: $blue-fonce;
    background-color: #156bb2;
    box-shadow: 0 2px 8px rgb(34 54 84 / 16%), 0 0 0 2px #4a98d9;
  }

  &:active {
    border-color: #3783c9;
    background-color: #3783c9;
  }
}

.btn.btn-secondary {
  border: 2px solid #095797;
  border-radius: 0;
  color: #095797;

  &:hover {
    color: #095797;
    background-color: rgb(9 87 151 / 16%);
  }

  &:focus {
    border-color: $blue-fonce;
    background-color: rgb(9 87 151 / 16%);
    box-shadow: 0 0 0 2px #4a98d9;
  }

  &:active {
    background-color: rgb(9 87 151 / 8%);
  }
}

.btn.btn-tertiary {
  color: #095797;
  border-color: transparent;

  &:hover {
    border: 2px solid transparent;
    text-decoration: underline;
    background-color: rgb(197 202 210 / 24%);
  }

  &:focus {
    border: 2px solid $blue-fonce;
    background-color: rgb(197 202 210 / 24%);
    box-shadow: 0 0 0 2px $blue-clair;
  }

  &:active {
    background-color: rgb(197 202 210 / 16%);
  }
}

.btn.btn-danger {
  color: #fff;
  border-color: $rouge;
  background-color: $rouge;

  &:hover {
    border-color: $rouge-fonce;
    background-color: $rouge-fonce;
  }

  &:focus {
    border-color: $blue-fonce;
    background-color: $rouge-fonce;
    box-shadow: 0 0 0 2px $blue-clair;
  }

  &:active {
    border-color: $rose;
    background-color: $rose;
  }
}

.btn-link {
  @extend %btn-one;
}

.btn-link-2 {
  @extend %btn-one;

  font-size: 14px;
  line-height: 20px;
  color: $blue-piv;
  text-decoration: none;
  background-color: $blue-pale;
  padding: 8px;
  font-weight: 500;
  text-transform: capitalize;
}

.no-text-transform {
  text-transform: none;
}

.btn-no-link {
  @extend %btn-one;

  font-size: 14px;
  line-height: 20px;
  color: $blue-fonce;
  text-decoration: none;
  background-color: $blue-pale;
  padding: 8px;
  font-weight: 500;
  text-transform: capitalize;
}

.btn-trans {
  @extend %btn-one;

  background-color: transparent;
  border: 2px solid $blue-moyen;
  color: $blue-moyen;

  &:hover {
    border-color: transparent;
    background-color: $blue-piv;
  }
}

%btn-icon-link {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: $blue-piv;
  font-weight: bold;
}

.btn-icon-link {
  @extend %btn-icon-link;
}

.btn-icon {
  @extend %btn-icon-link;

  border: 2px solid $blue-piv;
  padding: 8px;
}

.no-hover {
  pointer-events: none;
}
