/* eslint-disable-next-line private-name */
.chat-body {
  overflow: hidden;
}

/* Customize the scrollbar */
::-webkit-scrollbar {
  width: 6px; /* Adjust the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* Set the background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Set the background of the scrollbar thumb */
  border-radius: 3px; /* Add border-radius to the scrollbar thumb */
  opacity: 0; /* Set the initial opacity of the scrollbar thumb to 0 (hidden) */
  transition: opacity 300ms; /* Add transition for smooth showing/hiding */
}

#chat {
  transition: 0.4s ease;

  .messages-box {
    height: calc(100vh - 125px);
    padding: 0;
  }

  .conversations-list {
    height: 50vh;
    overflow-y: scroll;

    .current-convo {
      background-color: $blue-pale;
      color: $blue-fonce;
    }

    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: rgb(218 230 240);
      font-size: 13px;
      text-align: center;
      font-weight: bold;
      line-height: 48px;
      margin: 15px 0;
      color: rgb(34 54 84);
    }

    .convo-name {
      font-size: 16px;
      font-weight: 400;
      color: $blue-fonce;
      font-family: Roboto, sans-serif;
    }

    .convo-time {
      font-size: 12px;
      font-weight: 300;
      font-family: Roboto, sans-serif;
      color: $blue-fonce;
    }

    .convo-last-message {
      font-size: 12px;
      font-weight: 400;
      font-family: Roboto, sans-serif;
      color: $blue-fonce;
      max-width: 150px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .convo-unread {
      * {
        font-weight: bold;
      }
    }
  }

  .hide-scroll-bar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .message-input {
    overflow-y: scroll;
    overflow-wrap: break-word;
    resize: none;
    height: 25px;
  }

  .disable-scrollbars {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .conversation-main {
    height: 97%;
    padding: 0;
  }

  .conversation-header-name {
    font-size: 16px;
    font-weight: 600;
    font-family: Roboto, sans-serif;
  }

  .chat-tabs {
    min-height: 70px;
    max-height: 70px;
    align-items: flex-end;
    justify-content: flex-start;

    & li {
      & a {
        color: $blue-piv;
        font-size: 16px;
        font-family: "Open sans", sans-serif;

        &:hover {
          background-color: transparent !important;
        }
      }

      & a.active {
        font-weight: bold;
        color: $blue-fonce !important;
        border-bottom: 4px solid $blue-piv;
      }
    }
  }

  .convo-header {
    min-height: 70px;
    max-height: 70px;
    padding-left: 1.5rem;
  }

  .conversations-list-container {
    padding: 0;
    border-right: 1px solid $gris-clair;
  }

  .create-convo-button {
    font-size: 16px;
  }

  .convo-welcome-message {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-family: "Open sans", Roboto, sans-serif;
    font-size: 1rem;
    font-style: italic;
    font-weight: 600;
    color: $blue-fonce;

    .sub-welcome-message {
      font-weight: 400;
      margin-top: 15px;
    }
  }

  .file-picker {
    img {
      cursor: pointer;
    }
  }

  .chat-emoji-picker {
    img {
      cursor: pointer;
    }
  }

  .message-textarea {
    border: 0;
    font-family: "Open sans", Roboto, sans-serif;
    color: $blue-fonce;
  }

  .received-message-box {
    color: $blue-fonce;
    font-size: 14px;
    font-family: "Open sans", sans-serif;
    font-weight: 400;
    background-color: #fff;
    border: 1px solid $gris-clair;
    padding: 15px;
    max-width: 500px;
    min-width: 150px;
    word-wrap: break-word;
  }

  .message-sent-content {
    color: $blue-fonce;
    font-size: 14px;
    font-family: "Open sans", sans-serif;
    font-weight: 400;
    background-color: $gris-pale;
    border: 1px solid $gris-clair;
    padding: 10px;
    max-width: 500px;
    min-width: 150px;
  }

  .message-author {
    color: $blue-fonce;
    font-weight: 600;
    font-size: 12px;
  }

  .message-time {
    color: $blue-fonce;
    font-weight: 300;
    font-size: 12px;
  }

  .message-tooltip {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 1px 4px #22365429;
    right: 3px;
    top: -34px;
    z-index: 999999;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    cursor: pointer;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    color: $blue-fonce;

    &::after {
      content: "";
      position: absolute;
      bottom: calc(100% - 5px);
      left: 80%;
      top: 32px;
      transform: rotate(180deg);
      border-width: 7px;
      border-style: solid;
      border-color: transparent transparent #fff;
    }

    div {
      background: #fff;
      border: none;
      display: flex;

      img {
        max-width: unset !important;
        margin-right: 2px;
        width: 15px;
      }

      span {
        background-color: #fff;
      }
    }
  }

  .message-like-icon {
    cursor: pointer;
    height: 16px;
    width: 16px;
  }

  .settings-toggle {
    cursor: pointer;
  }

  .convo-settings-list {
    padding: 15px 0;
    border-radius: 0;
    padding-bottom: 5px;
    min-width: 300px;

    .dropdown-item {
      color: $blue-fonce;
      font-weight: 300;
      font-family: Roboto, "Open sans", sans-serif;

      &:hover {
        background-color: #dce6ef;
      }

      img {
        width: 22px;
        height: 22px;
      }
    }
  }

  .delete-file-container {
    width: 20px;
  }

  .delete-file-icon {
    cursor: pointer;
  }

  .file-container {
    cursor: pointer;
  }

  .message-file-name {
    color: $blue-piv;
    font-family: "Open sans", sans-serif;
  }

  .lettered-avatar {
    font-size: 14px;
    color: $blue-fonce;
    font-family: Roboto, sans-serif;
    border: 1px solid #fff;
    border-radius: 50%;
  }

  .lettered-avatar-wrapper {
    background-color: $blue-pale !important;
  }

  .first-participant {
    font-family: Roboto, sans-serif;
    color: $blue-fonce;
    font-weight: 200;
    line-height: 22px;
    margin-bottom: 6px;
  }

  .more-participants {
    font-family: Roboto, sans-serif;
    color: $blue-piv;
    font-weight: 200;
    font-style: italic;
    margin: 0;
  }

  .participants-select-container {
    min-height: 70px;
    max-height: 70px;
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: 1px solid rgb(222 226 230);

    .participants-tags {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    .lettered-avatar {
      font-size: 12px;
    }
  }

  @keyframes slide-in-from-right-animation {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }

    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }

  .slide-in-from-right {
    animation: slide-in-from-right-animation 0.5s ease-out;
  }

  .modal {
    background-color: rgb(0 0 0 / 50%);
  }

  .modal-close {
    cursor: pointer;
  }

  .modal-cancel-button {
    border-radius: 0;
  }

  .modal-title {
    font-size: 14px;
  }

  .modal-body {
    table {
      font-size: 14px;
      vertical-align: middle;
    }
  }

  .modal-content {
    border-radius: 0;
  }

  .custom-scroll-bar {
    overflow-y: scroll !important;
    overflow-x: hidden !important;

    /* width */
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 2px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #0069aa;
      border-radius: 2px;
    }

    /* Handle on hover */
    // &::-webkit-scrollbar-thumb:hover {
    //   background: #b30000;
    // }
  }

  @media (max-width: 768px) {
    .messages-box {
      -webkit-box-flex: 100%;
      flex: 100%;
      border: 0;
      left: 0;
      padding: 0 0 100px;
      position: fixed;
      overflow-y: scroll;
      width: 100%;
      height: 100%;
      transform: translate(100%);
      transition:
        visibility 0.4s ease,
        -webkit-transform 0.4s ease,
        transform 0.4s ease;
      background-color: #fff;
      top: 84px;

      &.opened-convo {
        transform: translate(0);
      }
    }

    .first-participant {
      margin-bottom: 4px;
    }

    .conversations-list-container {
      padding: 0;
      border-right: 0;
    }

    .conversation-main {
      height: 100%;
    }

    .participants-select-container {
      padding-left: 0;
      padding-right: 0;

      .participants-tags {
        display: block;
      }
    }

    .modal-fullscreen-md-down {
      height: 90%;
      min-height: 90%;
    }
  }
}
