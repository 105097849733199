.pg-news {
  .nx-btn-tag {
    background-color: $blue-pale;
    color: $blue-piv;
    font-size: 11pt;
    font-weight: 500;
    line-height: 120%;
    display: inline-block;
    padding: 12px;
    margin: 12px 12px 24px 0;
  }

  .input-wrapper-filter {
    margin-top: 15px;
    background-color: $gris-pale;
    color: $blue-fonce;
    padding: 15px 17px 15px 14px;
    box-shadow: 0 1px 4px $blue-fonce;
    border-radius: 8px;
  }

  .filter-header {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
  }

  .i-filter-label {
    display: flex;
  }

  .i-select-filter {
    display: flex;
    flex-direction: column;
    padding-left: 10px;

    label {
      display: flex;
      margin-top: 15px;

      span {
        display: inline-block;
        margin-left: 5px;
      }
    }

    input {
      width: auto;
    }
  }

  [data-display-res="list"] {
    .row {
      margin-bottom: 34px;
    }

    .card-thumb {
      width: 100%;
      height: 200px;
      background-size: cover;
      background-position: center;
      overflow: hidden;
    }

    .i-cont {
      .date {
        font-size: 12px;
        margin-bottom: 8px;
        margin-top: 8px;
        font-family: "Open Sans", sans-serif;
      }

      .title {
        font-size: 18px;
        font-weight: bold;
        color: $blue-piv;
      }
    }
  }

  [data-display-res="thumbs"] {
    .news-first-section {
      display: flex;
      gap: 42px;
      margin-bottom: 34px;

      img {
        max-width: 540px;
        width: 100%;
      }

      .i-cont {
        .date {
          font-size: 18px;
          margin-bottom: 16px;
        }

        .title {
          font-size: 18px;
          font-weight: bold;
          color: $blue-piv;
        }
      }
    }

    .news-first-section-thumb {
      width: 340px;
      height: 229px;
    }

    .news-thumbs-section {
      .card-thumb {
        width: 100%;
        height: 175px;
        background-size: cover;
        background-position: center;
        overflow: hidden;
      }

      [class*="col-"] {
        margin-bottom: 24px;
      }

      .i-cont {
        .date {
          font-size: 0.8rem;
          margin-bottom: 8px;
          margin-top: 8px;
        }

        .title {
          font-size: 1.2rem;
          font-weight: bold;
          color: $blue-piv;
        }
      }
    }
  }

  &.article {
    .top-date {
      font-size: 0.8rem;
      padding-top: 16px;
      margin-top: 16px;
      border-top: 1px solid $blue-fonce;
      color: $blue-fonce;
    }

    .general-img {
      width: 100%;
      height: 465px;
      overflow: hidden;
      background-position: center center;
      background-size: cover;
    }

    .img-desc {
      padding: 8px;
      background-color: $gris-pale;
      font-size: 0.7rem;
      margin: 16px 0;
      color: $blue-fonce;
    }

    .quote {
      width: 90%;
      max-width: 600px;
      margin: 34px auto;

      p {
        color: $blue-fonce;
        font-weight: 600;

        &::before {
          display: inline-block;
          content: "«";
        }

        &::after {
          display: inline-block;
          content: "»";
        }
      }

      .quote-name {
        font-weight: bold;
        color: $blue-fonce;

        &::before {
          display: inline-block;
          content: "—";
        }
      }

      .quote-job-title {
        font-size: 0.9rem;
        padding-left: 20px;
      }
    }
  }

  .nx-richtext-content {
    color: $blue-fonce;

    .nx-quote {
      border-width: 0;
      color: $blue-fonce;
      font-size: 18px;
      font-weight: 600;
      line-height: 160%;
      text-align: left;
      display: block;
      position: relative;
      padding: 0 32px;
      margin: 48px 0;

      &.nx-quote-long {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
      }

      &.nx-quote-short {
        font-size: 31px;
        line-height: 48px;
      }

      div.d-block {
        display: inline !important;
      }

      &::before {
        content: "« ";
        position: absolute;
        transform: translate(-1em, -1px);
      }

      &::after {
        content: " »";
      }
    }

    .nx-quote-author {
      color: $blue-fonce;
      font-size: 14px;
      font-weight: 300;
      line-height: 160%;
      display: block;
      max-width: 85%;
      position: relative;
      padding: 0 32px;
      margin: 48px 32px;

      &::before {
        content: "";
        background: $blue-fonce;
        display: block;
        width: 24px;
        height: 1px;
        position: absolute;
        top: 12px;
        transform: translate3d(-32px, 0, 0);
      }

      b,
      strong {
        font-size: 16px;
        font-weight: 600;
        line-height: 160%;
      }
    }

    .nx-quote + .nx-quote-author {
      margin-top: -32px;
    }
  }
}

.safe-text-container,
.nx-richtext-document {
  color: $blue-fonce;
}

html[lang^="en"] {
  .pg-news {
    .nx-richtext-content {
      .nx-quote {
        &::before {
          content: '" ';
          transform: translate3d(-0.7em, 0, 0);
        }

        &::after {
          content: ' "';
        }
      }

      p {
        font-size: 16px;
        line-height: 24px;
        color: $blue-fonce;
      }
    }

    .nx-richtext-document {
      font-size: 16px;
      line-height: 24px;
      color: $blue-fonce;
    }
  }
}
