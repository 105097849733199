.code {
  page-break-inside: avoid;
  display: block;
  padding: 3px 3px 2px;
  font-size: 14px;
  line-height: 20px;
  word-break: break-all;
  word-wrap: break-word;
  width: 100%;
  overflow-x: scroll;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  color: #053954;
  font-weight: bold;
}

.icon-bubble {
  text-align: center;
  display: inline-block;
  margin: 5px;

  img {
    width: 60px;
    height: 60px;
    border: 1px solid #3333;
    padding: 11px;
    border-radius: 5px;
    background-color: antiquewhite;
  }

  div {
    display: none;
    position: absolute;
    margin-top: 13px;
    margin-left: -60px;
    background: black;
    padding: 5px;
    color: white;
  }

  &:hover {
    img {
      background-color: #eee;
    }

    div {
      display: inline-block;
    }
  }
}

.color {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 25px;
  padding: 5px;
  text-shadow: black 0 0 4px;
  color: white;
  margin: 5px 3px;
}

.design-system {
  display: flex;

  .side-nav {
    flex: 0 0 250px;
    border-right: 1px solid #ccc;
    padding-top: 25px;

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;

        a {
          padding: 15px;
          border-bottom: 1px solid #ccc;
          display: block;
          color: $blue-piv;
          text-decoration: none;

          &:hover {
            background-color: $blue-piv;
            color: white;
          }
        }
      }
    }
  }
}
