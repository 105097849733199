/* Blue */
$blue: #1472bf;
$blue-pale: #dae6f0;
$blue-clair: #4a98d9;
$blue: #1472bf;
$blue-piv: #095797;
$blue-moyen: #19406c;
$blue-fonce: #223654;

/* Yellow/Jaunes */
$jaune-pale: #f8e69a;
$jaune-moyen: #e0ad03;
$jaune-fonce: #ad781c;

/* Green/Verts */
$vert-pale: #dcf0bb;
$vert: #4f813d;
$vert-fonce: #2c4024;

/* Gray/Gris */
$gris-pale: #f1f1f2;
$gris-clair: #c5cad2;
$gris: #8893a2;
$gris-moyen: #6d778a;
$gris-fonce: #4e5662;

/* Red/Rouges */
$orange-pale: #de724d;
$rose-pale: #ffdbd6;
$rose: #e58271;
$rouge: #cb381f;
$rouge-fonce: #692519;

/* Alerts */
$alert-red: #cb381c;
$alert-yellow: #fce58f;
$alert-blue: #d9e6f0;
$alert-pink: #edbab1;
