div[data-display-res="list"] {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .card-wrap {
    display: flex;
    gap: 24px;

    .list-thumb {
      width: 295px;
      height: 195px;
      background-position: center;
      background-size: cover;
    }

    .list-info {
      width: calc(100% - 295px - 24px);
      gap: 8px;
      display: flex;
      flex-direction: column;
    }

    .i-date {
      font-size: 12pt;
      color: #647287;
      font-weight: bold;
    }

    .i-title {
      font-size: 18pt;
      color: $blue-fonce;
      font-weight: bold;
    }

    .i-organizer {
      font-size: 12pt;
      color: $blue-fonce;
    }

    .i-location {
      display: flex;
      gap: 4px;
      color: #1479cc;
      font-size: 12pt;
      align-items: center;

      p {
        margin: 0;
      }
    }

    .event-action {
      margin-top: 8px;

      a {
        color: $blue-fonce;
        font-size: 11pt;
        border-radius: 4px;
        padding: 8px;
        border: 1px solid $blue-fonce;
        text-decoration: none;

        &:hover {
          background-color: rgba(9 87 151 / 16%);
        }
      }
    }
  }

  @media (max-width: $screen-sm-min) {
    .card-wrap {
      flex-direction: column;

      .list-thumb {
        width: 100%;
      }

      .list-info {
        width: 100%;
      }
    }
  }
}
