.pg-new-password {
  @extend %login;

  .form-footer {
    a:not(.btn-link) {
      color: $blue-piv;
      text-decoration: none;
      display: inline-flex;
      gap: 5px;
    }
  }
}
