.notification-list {
  .dropdown-menu {
    width: 280px;
    height: 510px;
    border-radius: 0;
    padding: 0;
    z-index: 999999;
    box-shadow: 0 2px 6px rgba(0 0 0 / 16%);
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .notification-element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    transition: 0.15s ease-in;
    border-bottom: 1px solid #e8e8e8;
    text-transform: lowercase;
    position: relative;
    color: $blue-fonce;

    &:hover {
      background-color: #f5f5f5;
      color: $blue-fonce;

      &::before {
        content: "";
        width: 4px;
        background: $blue;
        left: 0;
        top: 0;
        bottom: 0;
        position: absolute;
      }
    }

    &.unread {
      font-weight: 600;
      background: rgb(218 230 240 / 30%);
    }

    .unread {
      font-weight: 600;
    }
  }

  .delete-notification {
    cursor: pointer;
    width: 14px;
  }

  .infinite-scroll-component {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    .no-notifications {
      font-weight: 400;
      font-size: 12px;
      color: $gris-clair;
      text-align: center;
      padding: 15px;
      margin: 0;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }

  #notification-widget {
    height: 100%;
  }

  .empty-notifications {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
  }

  .dropdown-menu::before {
    content: "";
    position: absolute;
    top: 0;
    right: 3%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: white;
    border-top: 0;
    margin-left: -5px;
    margin-top: -10px;
  }
}

.top-text-block {
  display: block;
  padding: 10px 20px;
  clear: both;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 200;
  line-height: 1.4;
  color: $blue-fonce;
  white-space: inherit !important;
  border-bottom: 1px solid $gris-pale;
  position: relative;
  transition: 0.2s ease-in;
  width: 100%;
  word-wrap: break-word;

  &:hover {
    color: $blue-fonce;
  }

  .top-text-light {
    font-family: "Open sans", sans-serif;
    color: $blue-fonce;
    font-size: 0.8em;
  }
}

.top-head-dropdown {
  .dropdown-menu {
    width: 350px;
    height: 300px;
    overflow: auto;
  }

  li:last-child {
    .top-text-block {
      border-bottom: 0;
    }
  }
}

.topbar-align-center {
  text-align: center;
}

.view-all-notifications {
  padding: 12px 20px;
  border-bottom: 1px solid $gris-clair;
}

.loader-topbar {
  margin: 5px auto;
  border: 3px solid #f1f1f2;
  border-radius: 50%;
  border-top: 3px solid $gris-moyen;
  width: 22px;
  height: 22px;
  animation: spin-topbar 1s linear infinite;
}

@keyframes spin-topbar {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin-topbar {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
