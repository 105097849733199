#map,
#map-wrapper {
  position: relative;
}

.leaflet-container {
  height: 650px;

  &::after {
    z-index: 9999;
  }

  a.leaflet-popup-close-button {
    color: $blue-fonce;

    &:hover {
      color: $blue-piv;
    }
  }
}

.leaflet-div-icon {
  background-color: transparent;
  border: none;
}

.user-location {
  position: absolute;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: $gris-pale;
  width: 32px;
  height: 32px;
  right: 9.7%;
  top: 128px;
  z-index: 999;

  img {
    width: 20px;
    height: 20px;
  }

  @media screen and (max-width: 768px) {
    top: 178px;
    right: 8.2%;
  }

  .nx-map-locate-spinner {
    color: #135897;
    width: 16px;
    height: 16px;
  }
}

// Zoom control

.leaflet-control-container {
  width: 32px;
  height: 32px;
  position: absolute;
  right: 9.7%;
  top: 178px;

  @media screen and (max-width: 768px) {
    top: 230px;
    right: 8.2%;
  }

  .leaflet-control-zoom {
    border: none;
  }

  .leaflet-control {
    margin: 0;
  }

  .leaflet-bottom {
    display: none;
  }
}

.leaflet-touch {
  .leaflet-bar {
    a {
      width: 32px;
      height: 32px;
      line-height: initial;
      border-radius: 0 !important;
      margin: 0 0 16px;
      color: $blue-fonce;
      background-color: $gris-pale;
    }
  }
}

// Marker Cluster

.marker-cluster {
  background: #095797;
  border: 2px solid #fff;
  cursor: pointer;
  color: #fff;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-25%, -50%) rotate(-90deg);

  &.supplier {
    background: #4a98da;

    &::before {
      border-right-color: #4a98da;
    }
  }

  &.intervenant {
    background: #ad791d;

    &::before {
      border-right-color: #ad791d;
    }
  }

  &.contractor {
    background: #19406c;

    &::before {
      border-right-color: #19406c;
    }
  }

  &.notice {
    background: #4f813d;

    &::before {
      border-right-color: #4f813d;
    }
  }

  &.market {
    background: #6b778a;

    &::before {
      border-right-color: #6b778a;
    }
  }

  &::before {
    content: "";
    position: absolute;
    right: 100%;
    top: 13px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-right: 6px solid #095797;
    border-bottom: 6px solid transparent;
    transform: translate(1px, -8px);
  }

  & span {
    transform: rotate(90deg);
  }
}
