.pg-home {
  %section-rows {
    .section-title2 {
      color: $blue-piv;
      font-size: 14pt;
      font-weight: 500;
      margin-bottom: 2px;
    }

    .section-company-name {
      color: $blue-fonce;
      font-size: 14pt;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    .section-date {
      font-size: 11pt;
      color: $blue-fonce;
      margin-bottom: 1px;
    }

    .section-text {
      font-size: 12pt;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .section-cont {
      color: $blue-fonce;
    }

    .link {
      font-size: 11pt;
      color: $blue-piv;
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      margin-bottom: 16px;
    }

    .link:hover {
      color: var(--bs-link-hover-color);
      cursor: pointer;
    }

    hr {
      border-top: 2px solid #dbdee2;
      opacity: 1;
      margin-left: calc(var(--bs-gutter-x) * 0.5);
      margin-right: calc(var(--bs-gutter-x) * 0.5);
      margin-bottom: 8px;
    }
  }

  .section-rows {
    @extend %section-rows;
  }

  .triple-column-section {
    @extend %section-rows;

    background-color: #f1f1f2 !important;
    padding: 32px 0;

    .section-txt {
      font-weight: 300;
      margin-bottom: 16px;
    }

    .thumb {
      margin-top: 24px;
      margin-bottom: 16px;
    }

    .section-news-thumb {
      overflow: hidden;
      aspect-ratio: 16/11;
      position: relative;
      transition: opacity 0.3s;
      margin-top: 24px;
      margin-bottom: 16px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .section-news-thumb-default {
      background-color: #fff;
      height: 100%;
    }

    .section-news-thumb-img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .section-news-date {
      font-size: 11pt;
      color: $blue-fonce;
      margin-bottom: 1px;
    }

    .section-news-title {
      color: $blue-piv;
      font-size: 14pt;
      font-weight: 500;
      transition: color 0.3s;
      margin-bottom: 2px;

      &:hover {
        color: $blue-fonce !important;
      }
    }

    .section-news-text {
      color: $blue-fonce;
      font-weight: 400;
      margin-bottom: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .section-news-wrapper:hover .section-news-thumb {
      opacity: 0.8;
    }

    .section-news-wrapper:hover .section-news-title {
      color: $blue-fonce;
      cursor: pointer;
    }
  }
}

.home-header {
  padding-bottom: 50px;
  padding-top: 100px;

  .v-center {
    display: flex;
    align-items: center;
    color: $blue-fonce;
    text-align: justify;
  }

  .search-bar {
    max-width: 800px;
    margin: 0 auto;
  }
}

.tags-qty-underline {
  padding: 16px 0 0;
  background-color: #fafafa;
  color: $blue-fonce;

  @media (max-width: 576px) {
    padding: 16px 0;
  }

  .content {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: center;

      li {
        gap: 16px;
        align-items: center;
        display: inline-flex;
        margin-left: 16px;
        font-weight: 500;
        margin-bottom: 24px;
      }

      li:first-child {
        margin-left: 0;
      }
    }

    @media (max-width: 576px) {
      ul {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        li {
          margin: 8px 32px;
        }

        li:first-child {
          margin-left: 32px;
        }
      }
    }
  }

  .bubble {
    width: 40px;
    height: 40px;
    background: red;
    position: relative;
    border-radius: 6px;
    transform: rotate(-90deg);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(99 99 99 / 20%) 0 2px 8px 0;

    &::before {
      content: "";
      position: absolute;
      right: 100%;
      top: 12px;
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-right: 7px solid red;
      border-bottom: 8px solid transparent;
    }

    .qty {
      transform: rotate(90deg);
      color: white;
      font-weight: 500;
    }

    &.blue {
      background-color: #4a98da;

      &::before {
        border-right-color: #4a98da !important;
      }
    }

    &.blue-piv {
      background-color: #ad791d;

      &::before {
        border-right-color: #ad791d !important;
      }
    }

    &.blue-moyen {
      background-color: $blue-moyen;

      &::before {
        border-right-color: $blue-moyen !important;
      }
    }

    &.vert {
      background-color: $vert;

      &::before {
        border-right-color: $vert !important;
      }
    }

    &.vert-pale {
      background-color: #6b778a;

      &::before {
        border-right-color: #6b778a !important;
      }
    }
  }
}

.section-title {
  border-bottom: 2px solid #8994a3;
  color: $blue-fonce;
  font-size: 1.75rem;
  font-weight: bold;
  font-family: Roboto, "Open sans", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  margin-top: 32px;
  margin-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: calc(var(--bs-gutter-x) * 0.5);

  a {
    font-size: 14px;
    font-family: "Open Sans", serif;
    font-weight: normal;
  }
}

.info-boxes {
  justify-content: flex-start;
  margin: 32px auto;

  .nx-metals-widget-col {
    padding-left: calc((var(--bs-gutter-x) * 0.5) / 2);
    padding-right: calc((var(--bs-gutter-x) * 0.5) / 2);
    margin-top: 0;
    margin-bottom: calc(var(--bs-gutter-x) * 0.5);
  }

  .info-box {
    background-color: #f1f1f2;
    gap: 16px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    padding: 19px 12px;
    margin: 0 auto;

    .left {
      color: $blue-fonce;
      font-size: 13px;
      font-weight: 800;
      text-transform: uppercase;
    }

    .right {
      color: $blue-fonce;
      display: flex;
      gap: 8px;
      justify-content: space-around;
      align-items: stretch;

      .r-l-1 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-self: stretch;
        padding: 0 12px;

        .r-l-title {
          font-size: 9px;
          text-transform: uppercase;
          text-align: center;
          margin: 0 0 6px;
        }

        .r-l-stats {
          font-size: 11px;
          font-weight: 600;
          text-align: center;
        }
      }

      .r-r-1 {
        display: flex;
        flex-direction: column;
        align-self: stretch;

        .r-r-title {
          font-size: 9px;
          text-transform: uppercase;
          text-align: center;
          margin: 0 0 6px;
        }

        .r-r-stats {
          font-size: 17px;
          line-height: normal;
          display: flex;
          align-items: center;

          %arrow {
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            display: inline-block;
            width: 0;
            height: 0;
            margin: 0 6px 0 0;
          }

          .arrow-up {
            @extend %arrow;

            border-bottom: 5px solid green;
          }

          .arrow-down {
            @extend %arrow;

            border-top: 5px solid $rouge;
          }

          &.green {
            color: green;
          }

          &.red {
            color: $rouge;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .info-box {
      justify-content: space-between;
      max-width: 90%;
      min-width: 275px;
    }
  }
}

.leaflet-popup-content-wrapper {
  border-radius: 0;
}
