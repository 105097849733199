.pg-profile-public {
  .page-header {
    display: flex;
    justify-content: space-between;
  }

  .social-icons {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    gap: 16px;
  }

  .min-title {
    font-size: 21px;
    font-weight: bold;
  }

  .ids-info {
    display: flex;
    margin: 22px 0 16px;
    padding: 0;
    list-style: none;
    gap: 16px;
    color: $blue-fonce;

    li {
      display: flex;
      gap: 6px;

      img {
        width: 20px;
        height: 20px;

        &.nx-icon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .desc {
    margin-bottom: 22px;
  }

  .desc-list {
    display: flex;
    flex-direction: column;
    margin: 22px 0 16px;
    padding: 0;
    list-style: none;
    gap: 16px;

    strong {
      margin-right: 16px;
    }
  }

  .quality-certification-list {
    list-style: none;
    padding: 0;

    li {
      display: inline-block;
      margin-right: 16px;
    }
  }

  .col-left {
    // border-right: 1px solid $gris-clair;
    padding-left: 16px;
  }

  .col-right {
    padding-left: 16px;
  }

  .h-list {
    margin: 0;
    list-style: none;

    li {
      display: inline-block;
    }
  }

  .address-box {
    max-width: none;

    p {
      margin-bottom: 0;
    }
  }

  .map-demo {
    background-size: cover;
    background-position: center;
    height: 550px;
    width: 100%;
    margin: 40px 0 60px;

    .gm-style {
      .gm-style-iw-a {
        .gm-style-iw-t {
          overflow: visible !important;

          .gm-style-iw-c {
            border-radius: 0;
            overflow: visible !important;
            padding: 0;

            .gm-style-iw-d {
              overflow: visible !important;
              max-height: none !important;
              position: relative;
              padding: 0;

              &::after {
                content: "";
                border-top: 10px solid transparent;
                border-right: 10px solid #fff;
                border-bottom: 10px solid transparent;
                position: absolute;
                left: -10px;
                bottom: 50px;
              }

              .nx-map-popinfo {
                width: 340px;
                max-width: 340px;
                padding: 24px;

                .address-box {
                  border: none;
                  font-size: var(--bs-body-font-size);
                  font-weight: var(--bs-body-font-weight);
                  line-height: var(--bs-body-line-height);
                  gap: 16px;
                  display: flex;
                  width: 100%;
                  flex-direction: column;
                  padding: 0;

                  .nx-company-detail-succursale-title {
                    color: $blue-fonce;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 130%;
                    margin: 0 0 12px;
                  }

                  .nx-company-detail-succursale-address {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    gap: 8px;
                    color: $blue-fonce;
                  }
                }
              }
            }

            button.gm-ui-hover-effect {
              display: none !important;
            }
          }

          .gm-style-iw-tc {
            display: none !important;
          }
        }
      }
    }
  }

  h4 {
    color: $blue-piv;
  }

  a {
    color: $blue-piv;
  }

  .projects-info {
    .date-info {
      color: $gris;
      font-size: 0.9rem;
    }

    .address-info {
      gap: 8px;
      display: flex;
      align-items: center;

      p,
      a {
        margin: 0;
        color: $blue-fonce;
        text-decoration: none;
      }
    }
  }

  .documents-and-hyperlinks {
    .row {
      margin-bottom: 16px;
    }

    .doc-link {
      display: flex;
      gap: 8px;
    }

    h5 {
      color: $gris-moyen;
      font-size: 18px;
    }

    .btn-icon-link {
      font-size: 14px;
      font-weight: initial;
      text-decoration: none;
    }

    .date {
      color: $gris-moyen;
      font-size: 14px;
      margin-bottom: 16px;
    }
  }
}

.pg-profile {
  .container {
    margin-bottom: 80px;
  }

  .nx-form-multiple-errors {
    background: linear-gradient(to right, #ffdbd6 40px, #fff 40px);
    border: 1px solid $gris-clair;
    font-size: 14px;
    line-height: 120%;
    display: block;
    position: relative;
    margin: 0 0 32px;

    &::after {
      content: "";
      background-image: url("../../vendors/images/icons/circle-error-red.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 22px;
      left: 8px;
    }

    .nx-form-multiple-errors-title {
      color: $blue-fonce;
      font-size: 14px;
      font-weight: 600;
      line-height: 120%;
      display: block;
      padding: 24px 24px 12px 16px;
      margin: 0 0 0 40px;
    }

    .nx-form-multiple-errors-list {
      color: $blue-fonce;
      font-size: 16px;
      font-weight: 400;
      line-height: 120%;
      display: block;
      padding: 0 24px 24px 16px;
      margin: 0 0 0 64px;

      > li {
        margin: 14px 0;

        > a {
          color: $rouge;
          font-size: 14px;
          font-weight: 400;
          line-height: 120%;
          text-decoration: underline;
        }
      }
    }

    strong {
      display: block;
      padding: 24px 24px 24px 16px;
      margin: 0 0 0 40px;
    }
  }
}

@media (max-width: 767px) {
  .address-box {
    margin-bottom: 16px;
  }

  .pg-profile-public {
    .documents-and-hyperlinks {
      .doc-link {
        margin-bottom: 16px;
      }
    }
  }
}
