.pg-reset-password {
  @extend %login;

  h1 {
    color: $blue-fonce;
  }

  .nx-title-qc {
    max-width: 390px;
    color: $blue-fonce;
  }

  .nx-subtitle {
    font-weight: 600;
    max-width: 400px;
    margin: 20px 0 24px;
    color: $blue-fonce;
  }

  .nx-text {
    max-width: 430px;
    color: $blue-fonce;
  }

  .form-card {
    margin-top: 64px;
    color: $blue-fonce;

    .max-width-sm {
      max-width: 380px;
    }

    .form-header {
      align-items: flex-start;

      .nx-title-login {
        font-size: 26pt;
        margin: 0 0 28px;

        &::after {
          display: none;
        }
      }
    }

    .alert-block.alert-error {
      background: linear-gradient(to right, #ecbab1, #ecbab1 40px, transparent 40px);
      border: 1px solid #ccc;
      border-radius: 0;
      position: relative;
      padding: 20px 48px;

      &::before {
        color: $blue-fonce;
        font-size: 16px;
        font-weight: 600;
        line-height: 120%;
        display: block;
        padding: 0 6px;
      }

      &::after {
        content: "";
        background-image: url("../../vendors/images/icons/circle-error-red.svg");
        background-size: contain;
        display: inline-block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 12px;
        left: 7px;
      }

      ul {
        list-style: none;
        padding: 0 6px;
        margin: 0;

        li {
          color: $blue-fonce;
          font-size: 14px;
          font-weight: 400;
          line-height: 120%;
          list-style: none;
          padding: 4px 0 0;
          margin: 0;
        }
      }
    }

    input[type="submit"] {
      font-weight: 500;
      padding: 16px 24px;
    }

    .nx-link-backtohome {
      display: flex;
      margin: 32px 0 0;

      .nx-link-text {
        display: inline-block;
        margin: 0 0 0 12px;
      }
    }
  }

  .form-footer {
    a:not(.btn-link) {
      color: $blue-piv;
      text-decoration: none;
      display: inline-flex;
      gap: 5px;
    }
  }
}

html[lang^="fr"] {
  .pg-reset-password {
    .form-card {
      .alert-block.alert-error {
        &::before {
          content: "Vous ne pouvez pas vous connecter avec ces identifiants.";
        }
      }
    }
  }
}

html[lang^="en"] {
  .pg-reset-password {
    .form-card {
      .alert-block.alert-error {
        &::before {
          content: "You cannot log in with these credentials.";
        }
      }
    }
  }
}
