%login {
  .rg-row {
    display: flex;
    min-height: 60vh;
    width: 100%;

    .rg-col {
      display: flex;

      &.left {
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex: 0 40%;
        flex-direction: column;
        align-items: flex-end;
        padding: 100px 70px;

        .content {
          flex-direction: column;
          gap: 25px;
          width: 100%;
          max-width: 450px;

          img {
            width: 100%;
            max-width: 260px;
            margin: 50px auto;
            display: inherit;
          }
        }
      }

      &.right {
        flex: 0 60%;
        flex-direction: column;
        background-color: $blue-pale;
        padding: 25px;

        .alert-message.toasted {
          width: 100%;
          max-width: 800px;
          margin: 0 auto -38px;
        }
      }
    }
  }

  .info-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $blue-moyen;
    color: white;
    font-size: 10pt;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    cursor: pointer;
  }

  @media (max-width: $screen-md-min) {
    padding-top: 0;

    .rg-row {
      .rg-col.left {
        display: none;
      }

      .rg-col.right {
        flex: 100%;
      }
    }
  }
}

.pg-login {
  @extend %login;

  div.alert.alert-block.alert-error {
    background: linear-gradient(to right, #f8e69a 40px, #fff 40px);
    border: 1px solid $gris-clair;
    border-radius: 0;
    font-size: 14px;
    line-height: 120%;
    display: block;
    position: relative;

    &::after {
      content: "";
      background-image: url("../../vendors/images/icons/triangle-warning-yellow.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 18px;
      left: 8px;
    }

    > ul {
      color: $blue-fonce;
      font-size: 14px;
      font-weight: 400;
      line-height: 120%;
      list-style-type: none;
      display: block;
      padding: 24px 24px 24px 16px;
      margin: 0 0 0 48px;

      > li {
        list-style: none;
        padding: 0;
        margin: 0;
      }
    }
  }

  .nx-title-qc {
    max-width: 390px;
    color: $blue-fonce;
  }

  .nx-subtitle {
    font-weight: 600;
    max-width: 400px;
    margin: 20px 0 24px;
    color: $blue-fonce;
  }

  .nx-text {
    max-width: 430px;
    color: $blue-fonce;
  }

  .form-card {
    margin-top: 64px;
    color: $blue-fonce;

    .max-width-sm {
      max-width: 380px;
    }

    .form-header {
      align-items: flex-start;

      .nx-title-login {
        font-size: 26pt;
        margin: 0 0 28px;

        &::after {
          display: none;
        }
      }
    }

    .nx-btn-login-icon img {
      transform: rotate(-90deg);
      margin: 0 12px 0 0;
    }

    .nx-link-forgotpass a {
      text-decoration: underline;
    }

    .nx-flex-register {
      justify-content: flex-start;
      color: $blue-fonce;
    }

    .nx-btn-register {
      border-radius: 0;
    }
  }
}
