.pg-registration {
  @extend %login;

  .nx-title-qc {
    max-width: 390px;
    color: $blue-fonce;
  }

  .nx-subtitle {
    font-weight: 600;
    max-width: 400px;
    margin: 20px 0 24px;
    color: $blue-fonce;
  }

  .nx-text {
    max-width: 430px;
    color: $blue-fonce;
  }

  .form-card {
    margin-top: 64px;
    color: $blue-fonce;

    .max-width-sm {
      max-width: 380px;
    }

    .form-header {
      align-items: flex-start;

      .nx-title-login {
        font-size: 26pt;
        margin: 0 0 28px;

        &::after {
          display: none;
        }
      }

      .nx-subtitle-createstep {
        font-size: 20pt;
      }
    }

    .crispy-form {
      .controls {
        input[type="checkbox"] {
          vertical-align: middle;
          display: inline-block;
        }
      }

      .form-actions {
        input[type="submit"]#submit-id-submit {
          width: auto;
          padding: 12px 38px;
        }
      }
    }
  }

  .rg-row {
    .rg-col {
      &.left {
        background-size: auto !important;
      }
    }
  }

  .input-wrapper {
    label[for*="member_cat"] {
      display: flex;
      gap: 10px;

      input {
        width: auto;
      }
    }
  }

  .nx-form-multiple-errors {
    background: linear-gradient(to right, #ffdbd6 40px, #fff 40px);
    border: 1px solid $gris-clair;
    font-size: 14px;
    line-height: 120%;
    display: block;
    position: relative;

    &::after {
      content: "";
      background-image: url("../../vendors/images/icons/circle-error-red.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 22px;
      left: 8px;
    }

    .nx-form-multiple-errors-title {
      color: $blue-fonce;
      font-size: 14px;
      font-weight: 600;
      line-height: 120%;
      display: block;
      padding: 24px 24px 12px 16px;
      margin: 0 0 0 40px;
    }

    .nx-form-multiple-errors-list {
      color: $blue-fonce;
      font-size: 16px;
      font-weight: 400;
      line-height: 120%;
      display: block;
      padding: 0 24px 24px 16px;
      margin: 0 0 0 64px;

      > li {
        margin: 14px 0;

        > a {
          color: $rouge;
          font-size: 12px;
          font-weight: 400;
          line-height: 120%;
          text-decoration: underline;
        }
      }
    }

    strong {
      display: block;
      padding: 24px 24px 24px 16px;
      margin: 0 0 0 40px;
    }
  }

  .confirmation-message {
    margin-bottom: 28px;
  }
}
