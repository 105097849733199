.w-sm {
  max-width: 365px;
}

.form-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 26px;
}

textarea,
select {
  width: 100%;
}

input {
  font-size: 12pt;
  width: 100%;

  &::placeholder {
    font-size: 12pt;
    color: $gris-moyen;
  }
}

label.strong,
.i-label.strong {
  font-weight: bold;
}

.input-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 8px;

  label {
    font-size: 12pt;
    color: $blue-fonce;
    width: 100%;
    margin-bottom: 8px;

    &.strong {
      font-weight: bold;
    }

    span {
      color: $rouge;
      font-size: 12pt;
      margin-right: 5px;
    }
  }

  input {
    width: 100%;
    border: 1px solid $gris;
    background-color: #fff;
    padding: 8px;
  }

  textarea {
    padding: 8px;
    width: 100%;
    min-height: 100px;
    border-color: $gris;
  }

  select {
    padding: 8px;
    width: 100%;
    border-color: $gris;
  }
}

.input-wrapper-with-icon {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 8px;

  label {
    font-size: 12pt;

    span {
      color: $rouge;
      font-size: 12pt;
      margin-right: 5px;
    }

    &.strong {
      font-weight: bold;
    }
  }
}

.input-wrapper-file {
  width: 100%;
  margin-bottom: 8px;

  input {
    position: absolute;
    left: -99999rem;
  }

  label {
    width: 100%;
  }

  .i-label {
    font-weight: bold;
  }

  .i-wrapper {
    display: flex;
    border: 1px solid $gris;
    background-color: transparent;
    padding: 5px;
    height: 40px;
    cursor: pointer;
  }

  .i-input {
    width: calc(100% - 20px);
    overflow: hidden;
  }

  .i-input span {
    opacity: 0.7;
  }

  .i-icon {
    width: 20px;
  }
}

.input-wrapper-selection {
  margin-bottom: 8px;

  .i-label {
    font-weight: bold;
    margin-bottom: 8px;
  }

  .i-input {
    border: 1px solid #ccc;
    display: flex;
    padding: 8px;
    cursor: pointer;

    input {
      cursor: pointer;
      border: 0;
    }

    img {
      width: 20px;
    }
  }

  .i-select {
    border: 1px solid $gris-clair;
    display: none;

    input {
      width: 20px;
      cursor: pointer;
    }

    .i-option {
      border: 0;
      display: flex;
      padding: 8px;
      gap: 8px;
      cursor: pointer;

      &:hover {
        background-color: $gris-pale;
      }
    }

    &.show {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      max-width: inherit;
      background-color: white;
      z-index: 1;
      box-shadow: rgba(99 99 99 / 20%) 0 2px 8px 0;
    }
  }
}

.input-wrapper-with-icon .input-box {
  width: 100%;
  border: 1px solid $gris;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;

  img {
    width: 18px;
  }

  input {
    width: 100%;
    background-color: transparent;
    border: 0;
  }
}

.radio-item {
  display: inline-block;
  position: relative;
  margin: 8px 16px 0 0;
  cursor: pointer;

  input[type="radio"] {
    display: none;
  }

  label {
    color: $blue-piv;
    font-weight: 400;

    &::before {
      content: "sdsd ";
      display: inline-block;
      position: relative;
      top: 5px;
      margin: 0 5px 0 0;
      width: 20px;
      height: 20px;
      border-radius: 11px;
      border: 2px solid $blue-piv;
      background-color: transparent;
    }
  }

  input[type="radio"]:checked + label::after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 4px;
    content: " ";
    display: block;
    background: $blue-piv;
  }
}

.blue-cube-wrapper {
  text-align: center;

  p {
    color: $blue-piv;
    font-size: 0.8rem;
    margin-top: 5px;
    font-weight: 700;
  }

  .blue-cube {
    background-color: $blue-piv;
    display: inline-flex;
    padding: 0;
    width: 64px;
    height: 64px;
    justify-content: center;
    align-items: center;
    color: $blue-fonce;

    img {
      width: auto;
    }
  }
}

.range-sliders {
  .slide-container {
    width: 100%;
  }

  .slider-bar {
    appearance: none;
    width: 100%;
    height: 8px;
    background: $gris-clair;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
  }

  .slider-bar:hover {
    opacity: 1;
  }

  .slider-bar::-webkit-slider-thumb {
    appearance: none;
    width: 25px;
    height: 25px;
    background: white;
    border-radius: 50px;
    border: 1px solid $gris-clair;
    cursor: pointer;
    box-shadow: rgba(99 99 99 / 20%) 0 2px 8px 0;
  }

  .slider-bar::-moz-range-thumb {
    appearance: none;
    width: 25px;
    height: 25px;
    background: white;
    border-radius: 50px;
    border: 1px solid $gris-clair;
    cursor: pointer;
    box-shadow: rgba(99 99 99 / 20%) 0 2px 8px 0;
  }

  .slider-bar::-moz-range-progress {
    background-color: red;
    height: 1em;
    color: red;
  }

  .slide-info {
    display: flex;
    justify-content: space-between;
    font-size: 9pt;
    font-weight: 600;
    margin-top: 30px;
  }

  .set-tooltip {
    position: relative;
    left: 0;
    width: fit-content;
    height: 1em;
    text-align: center;
    color: #212529;
    white-space: nowrap;
    margin-top: -40px;
    font-size: 9pt;
    font-weight: 600;
  }
}

.wrapper-filter {
  background-color: $gris-pale;
  border-radius: 8px;
  box-shadow: rgba(0 0 0 / 16%) 0 1px 4px;

  .open {
    display: flex !important;
  }

  .link-like {
    display: inline-block;
    padding: 0;
    margin: 0 0 0 26px;
    border: 0;
    background-color: transparent;
    color: $blue-moyen;
    width: fit-content;
    font-weight: bold;
  }

  .i-filter-label {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 1.3rem;
    color: $blue-fonce;
    cursor: pointer;
    padding: 24px;
  }

  input[readonly] {
    display: none;
  }

  .i-filter-content {
    display: none;
    flex-direction: column;

    .inputs-group-checkboxes {
      flex-direction: column;
      gap: 16px;
      padding: 24px;
      display: flex;
    }

    label {
      display: flex;
      gap: 8px;
      font-weight: 400;
      font-size: 1.1rem;
      cursor: pointer;

      input {
        width: 16px;
      }

      img {
        width: 18px;
      }
    }

    .section-wrapper-filter {
      .i-filter-content {
        padding: 24px;
        gap: 16px;
      }

      .i-filter-label {
        font-weight: 400;

        &.open {
          background-color: $blue-pale;
        }

        &.open + .i-filter-content {
          &.open {
            background-color: $blue-pale;
          }
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid $gris-clair;
      }
    }
  }

  .sub-section-wrapper-filter {
    .sub-section-title {
      font-size: 16px;
      font-weight: bold;
    }

    .dropdown-group {
      background-color: white;
      margin-top: 16px;
      padding: 16px;

      .dropdown-group-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
      }

      .dropdown-group-content {
        margin-top: 16px;
        flex-direction: column;
        gap: 16px;
        display: none;

        label {
          font-size: 14px;
        }
      }
    }
  }
}

.input-wrapper-radio {
  margin-bottom: 8px;

  .i-label {
    font-weight: bold;

    span {
      color: $rouge;
      font-size: 12pt;
      margin-right: 5px;
    }
  }

  .radio-item:last-child {
    margin-right: 0;
  }
}

.error-msg {
  display: none;
  color: red;
  font-size: 12pt;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #c5cad2 0% 0% no-repeat padding-box;
  transition: 0.4s;
  opacity: 1;

  &::before {
    position: absolute;
    content: "";
    height: 28px;
    width: 28px;
    left: 1px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
  }

  &.round {
    border-radius: 34px;

    &::before {
      border-radius: 50%;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 32px;

  [type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $blue;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $blue-piv;
    }

    &:not(:checked) + .slider::before {
      border: 1px solid #6b778a;
    }

    &:checked + .slider::before {
      transform: translateX(26px);
      border: 1px solid #22365480;
      background: #fff 0% 0% no-repeat padding-box;
      opacity: 1;
    }
  }
}

.form-card {
  box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
  width: 100%;
  max-width: 800px;
  min-height: 250px;
  margin: 100px auto;
  padding: 75px;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: $blue-fonce;
}

.max-width-sm {
  max-width: 350px;
  margin: 0 auto;
  width: 100%;
}

%crispy-form {
  fieldset {
    margin-bottom: 32px;
  }

  .control-group {
    margin-bottom: 12pt;

    .control-label {
      font-weight: 500;
      color: $blue-fonce;
    }

    .controls {
      width: 100%;
      font-family: "Open sans", sans-serif;
      color: $blue-fonce;

      label.radio {
        display: inline-flex;
        align-items: center;
        margin-right: 16px;

        @media (min-width: 768px) {
          gap: 8px;
        }
      }
    }
  }

  input,
  textarea {
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(136 147 162);
    border-image: initial;
    border-radius: 0;
    width: 100%;
    padding: 8px;
    color: $blue-fonce;
  }

  input[type="checkbox"] {
    margin-right: 5px;
    border: 1.5px solid $blue-fonce;

    &:checked {
      background-color: $blue;
      border-radius: 0;
      border: 1.5px solid transparent;
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type="number"] {
    appearance: textfield;
    color: $blue-fonce;
  }

  label {
    font-weight: 500;
    color: $blue-fonce;
    margin-top: 16px;
  }

  .textinput {
    width: 100%;
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(136 147 162);
    border-image: initial;
    padding: 8px;
    color: $blue-fonce;
  }

  .select2-selection {
    border-radius: 0 !important;
  }

  span[class*="asterisk"] {
    color: $rouge;
    margin: 0;
  }

  [id="add_more"],
  [id="add_more_social_media"],
  [id="bonscoups_documents_add_more"] {
    background: transparent;
    border: 1px solid #095797;
    border-radius: 0;
    color: #095797;
    font-size: 16px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    padding: 12px 24px;
    margin: 1rem 0 0;
  }

  [id="div_id_logo"] {
    a {
      display: block;
    }

    [type="checkbox"] {
      width: auto;
    }
  }

  .control-group.error {
    .controls {
      input,
      textarea {
        border-color: $alert-red;
        border-width: 2px;
      }
    }
  }

  .form-footer {
    .flex-row {
      display: flex;
      gap: 32px;
      justify-content: center;
      align-items: center;
    }
  }

  .help-inline,
  .help-block {
    font-size: 16px;
    margin: 8px 0;
    display: block;

    strong {
      color: $alert-red;
      font-weight: 500;

      &::before {
        content: "";
        background-image: url("../../vendors/images/icons/triangle-warning-red.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        vertical-align: top;
        display: inline-block;
        width: 24px;
        height: 24px;
        margin: 0 6px 0 0;
      }
    }
  }

  .alert-error {
    color: $alert-red;
    padding: 0;
    font-size: 16px;
    font-weight: 500;

    ul {
      padding: 0 0 0 16px;
      margin: 0;
    }
  }
}

%crispy-form-accordion {
  .accordion {
    margin-bottom: 32px;
  }

  .control-group {
    margin-bottom: 12pt;

    .control-label {
      font-weight: 500;
      color: $blue-fonce;
    }

    .controls {
      width: 100%;
      font-family: "Open sans", sans-serif;
      color: $blue-fonce;

      label.radio {
        display: inline-flex;
        align-items: center;
        margin-right: 16px;

        @media (min-width: 768px) {
          gap: 8px;
        }
      }
    }
  }

  input,
  textarea {
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(136 147 162);
    border-image: initial;
    border-radius: 0;
    width: 100%;
    padding: 8px;
    color: $blue-fonce;
  }

  input[type="checkbox"] {
    margin-right: 5px;
    border: 1.5px solid $blue-fonce;

    &:checked {
      background-color: $blue;
      border-radius: 0;
      border: 1.5px solid transparent;
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type="number"] {
    appearance: textfield;
    color: $blue-fonce;
  }

  label {
    font-weight: 500;
    color: $blue-fonce;
    margin-top: 16px;
  }

  .textinput {
    width: 100%;
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(136 147 162);
    border-image: initial;
    padding: 8px;
    color: $blue-fonce;
  }

  .select2-selection {
    border-radius: 0 !important;
  }

  span[class*="asterisk"] {
    color: $rouge;
    margin: 0;
  }

  [id="add_more"],
  [id="add_more_social_media"],
  [id="bonscoups_documents_add_more"] {
    background: transparent;
    border: 1px solid #095797;
    border-radius: 0;
    color: #095797;
    font-size: 16px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    padding: 12px 24px;
    margin: 1rem 0 0;
  }

  [id="div_id_logo"] {
    a {
      display: block;
    }

    [type="checkbox"] {
      width: auto;
    }
  }

  .control-group.error {
    .controls {
      input,
      textarea {
        border-color: $alert-red;
        border-width: 2px;
      }
    }
  }

  .form-footer {
    .flex-row {
      display: flex;
      gap: 32px;
      justify-content: center;
      align-items: center;
    }
  }

  .help-inline,
  .help-block {
    font-size: 16px;
    margin: 8px 0;
    display: block;

    strong {
      color: $alert-red;
      font-weight: 500;

      &::before {
        content: "";
        background-image: url("../../vendors/images/icons/triangle-warning-red.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        vertical-align: top;
        display: inline-block;
        width: 24px;
        height: 24px;
        margin: 0 6px 0 0;
      }
    }
  }

  .alert-error {
    color: $alert-red;
    padding: 0;
    font-size: 16px;
    font-weight: 500;

    ul {
      padding: 0 0 0 16px;
      margin: 0;
    }
  }
}

.crispy-form {
  @extend %crispy-form;
  @extend %crispy-form-accordion;

  .control-group {
    &.error {
      .controls {
        .select2.select2-container {
          border: 2px solid #cb381f;
        }
      }
    }
  }
}

.custom-form {
  @extend %crispy-form;
}

.pg-registration {
  .crispy-form {
    .control-group {
      .controls {
        label.radio {
          display: flex;

          [type="radio"] {
            width: auto;
          }
        }
      }
    }
  }
}

@media (max-width: $screen-md-min) {
  .form-card {
    margin: 0 auto;
    padding: 75px 25px;
  }

  .crispy-form {
    .form-footer {
      .flex-row {
        flex-direction: row;
      }
    }
  }
}

/*  select2 styles   */
/* stylelint-disable */

.select2-container--default {
  .select2-selection--single {
    .select2-selection {
      &__rendered {
        line-height: 40px !important;
        color: $blue-fonce !important;
      }

      &__arrow {
        height: 40px !important;
      }
    }
  }
}

.select2 {
  font-family: "Open Sans", sans-serif;
  color: $blue-fonce !important;

  .select2-selection[role="combobox"] {
    border: 1px solid #8690a2;
    min-height: 40px;

    li[class*="select2-selection__choice"] {
      background-color: #dae6f0;
      border: none;
      border-radius: 0;
      color: #095797;
      font-size: 14px;
      font-weight: 400;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 120px;
      position: relative;
      padding: 4px 24px 4px 9px;

      > span[role="presentation"] {
        background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M8.65538 7.99981L13.8615 2.78442C14.0462 2.60904 14.0462 2.31365 13.8615 2.12904C13.6862 1.95365 13.3908 1.95365 13.2062 2.12904L8 7.34442L2.78462 2.13827C2.60923 1.95365 2.31385 1.95365 2.13846 2.13827C1.96308 2.32288 1.95385 2.60904 2.13846 2.78442L7.34462 7.99981L2.13846 13.2152C1.95385 13.3906 1.95385 13.686 2.13846 13.8706C2.23077 13.9629 2.35077 14.009 2.46154 14.009C2.57231 14.009 2.70154 13.9629 2.78462 13.8706L8 8.65519L13.2154 13.8706C13.3077 13.9629 13.4277 14.009 13.5385 14.009C13.6492 14.009 13.7785 13.9629 13.8615 13.8706C14.0369 13.686 14.0369 13.3998 13.8615 13.2152L8.65538 7.99981Z" fill="%23095797"/></svg>');
        background-size: contain;
        color: #095797;
        font-size: 0;
        line-height: 0;
        width: 14px;
        height: 14px;
        position: absolute;
        top: 8px;
        right: 4px;
        opacity: 1;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  .select2-selection[aria-expanded="true"][role="combobox"] {
    border-color: #aaa;
  }
}

.select2-container {
  .select2-dropdown {
    border-radius: 0;

    .select2-results
      li[aria-selected="true"]:not([class*="select2-results__option--highlighted"]) {
      background-color: #dbe5f0;
    }
  }
}
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  box-sizing: border-box;
  height: 10px;
  width: 10px;
  border-style: solid;
  border-color: $blue-fonce !important;
  border-width: 1px 0px 0px 1px !important;
  transform: rotate(45deg);
  left: 8px !important;
  top: 20px !important;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  height: 10px !important;
  width: 10px !important;
  border-style: solid;
  border-color: $blue-fonce !important;
  border-width: 1px 0px 0px 1px !important;
  transform: rotate(224deg);
  left: 8px !important;
  top: 15px !important;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  right: 8px !important;
}

/* Style for the selected option */

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #fff !important ;
  color: $blue-fonce !important;
  font-weight: 400;
}

.custom-select2-class .select2-results__option[aria-selected="true"] {
  background-color: #1479cc !important ;
  color: #fff !important;
  font-weight: 400;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected="false"] {
  background-color: #dae6f0 !important ;
  color: $blue-fonce !important;
  font-weight: 400;
}

.select2-results__option[aria-selected] {
  color: $blue-fonce !important;
}

.select2-results__option:not(:first-child) {
  padding: 0 !important;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 261px !important;

  &::-webkit-scrollbar-thumb {
    background-color: #095797;
  }
}

.select2-container .select2-search--inline {
  height: 30px;
  & .select2-search__field {
    padding: 8px !important;
    margin: 0 !important;
  }
}

.select2-container--default .select2-selection--single {
  border-radius: 0;
}

.custom-select2-class .select2-results__option {
  padding: 8px 6px !important;
}

.select2-results__option[data-select2-id] {
  padding: 0 !important;
}

.custom-select2-class .select2-results__option {
  padding: 6px 6px 6px 0 !important;
}

#id_code_scian_primaire option:first-child {
  display: none;
}

/*   flatPicker styles  */

.flatpickr-calendar {
  border-radius: 0;
  border: 1px solid $blue;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: transparent;
  bottom: 90%;
  border: 0;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: transparent;
  bottom: 90%;
  border: 0;
}

span.flatpickr-weekday {
  color: $blue-fonce !important;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  fill: $blue-fonce;
  top: -4px;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: $blue-fonce;
}
.flatpickr-day {
  color: $blue-fonce;
  border-radius: 0;
}

.flatpickr-time input {
  color: $blue-fonce;
}
.flatpickr-time .flatpickr-am-pm {
  color: $blue-fonce;
}
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  color: $blue-fonce;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  color: $blue-fonce;
}
.flatpickr-current-month .numInputWrapper {
  color: $blue-fonce;
}

.datetimeinput.flatpickr-input.active {
  border: 1px solid $blue;
}
.datetimeinput {
  position: relative !important;
}

.flatpickr-icone {
  height: 10px;
  width: 20px;
  background-image: url("../../vendors/images/icons/calendar-empty-b.svg");
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  bottom: 12px;
  top: 20%;
}
