.pg-event-details {
  .nx-page-event-detail {
    margin: 0 auto 0 0;

    .header-underline {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $gris-clair;
      padding: 16px 0;
      color: $blue-fonce;

      .nx-event-detail-date-live {
        display: flex;
        align-items: center;

        a {
          color: $blue-fonce;
          transition: all 0.3s;
          display: flex;
          align-items: center;
          gap: 6px;
        }

        a:hover {
          opacity: 0.7;
        }

        a:active {
          opacity: 0.4;
        }
      }
    }

    .header-underline-2 {
      font-size: 16px;
      font-weight: 300;
      line-height: 120%;
      padding: 16px 0 0;
      margin: 0 0 12px;
      color: $blue-fonce;
    }

    .nx-event-detail-categories {
      padding: 16px 0;

      span {
        background-color: $blue-pale;
        color: $blue-piv;
        font-size: 14px;
        font-weight: 600;
        line-height: 120%;
        display: inline-block;
        padding: 12px;
        margin: 6px 12px 6px 0;
      }
    }

    .nx-event-detail-diffusion {
      font-size: 20px;
      line-height: 120%;
      padding: 0;
      margin: 12px 0 32px;
    }

    .nx-event-detail-image {
      margin: 12px 0;
    }

    .nx-event-detail-image-caption {
      background-color: $gris-pale;
      color: $blue-fonce;
      font-size: 13px;
      font-weight: 400;
      line-height: 150%;
      padding: 12px;
      margin: 12px 0;
    }

    .nx-event-detail-description {
      color: $blue-fonce;
      font-size: 16px;
      font-weight: 400;
      line-height: 160%;
      padding: 12px 1px;
      margin: 12px 0;
    }

    .nx-event-detail-lastupdate {
      color: $blue-fonce;
      font-size: 12px;
      line-height: 120%;
      margin: 12px 0 120px;
    }
  }

  .page-header {
    border-bottom: 1px solid $blue-fonce;
    padding-bottom: 25px;
  }

  .btn-v2 {
    padding: 8px 16px;
    border: 1px solid $blue-fonce;
    border-radius: 8px;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    font-weight: 500;
  }

  p strong {
    font-weight: 500;
  }

  .img-w-full {
    width: 100%;
    height: 465px;
    object-fit: contain;
  }

  .event-details-content {
    h3 {
      font-size: 16pt;
      color: $blue-fonce;
    }

    h4 {
      font-size: 12pt;
      color: #647287;
      text-transform: uppercase;
      font-weight: bold;
    }

    p {
      font-size: 12pt;

      strong {
        font-weight: bold;
      }
    }

    ul {
      padding: 0;
      margin-left: 14px;

      li {
        list-style: none;
        text-indent: -14px;

        &::before {
          content: "-";
          margin-right: 8px;
        }
      }
    }
  }

  .nx-richtext-content {
    color: $blue-fonce;

    .nx-quote {
      border-width: 0;
      color: $blue-fonce;
      font-size: 18px;
      font-weight: 600;
      line-height: 160%;
      text-align: left;
      display: block;
      position: relative;
      padding: 0 32px;
      margin: 48px 0;

      &::before {
        content: "« ";
        position: absolute;
        transform: translate(-1em, -1px);
      }

      &::after {
        content: " »";
      }
    }

    .nx-quote-author {
      color: $blue-fonce;
      font-size: 14px;
      font-weight: 300;
      line-height: 160%;
      display: block;
      max-width: 85%;
      position: relative;
      padding: 0 32px;
      margin: 48px 32px;

      &::before {
        content: "";
        background: $blue-fonce;
        display: block;
        width: 24px;
        height: 1px;
        position: absolute;
        top: 12px;
        transform: translate3d(-32px, 0, 0);
      }

      b,
      strong {
        font-size: 16px;
        font-weight: 600;
        line-height: 160%;
      }
    }

    .nx-quote + .nx-quote-author {
      margin-top: -32px;
    }
  }
}

html[lang^="en"] {
  .pg-event-details {
    .nx-richtext-content {
      .nx-quote {
        &::before {
          content: '" ';
          transform: translate3d(-0.7em, 0, 0);
        }

        &::after {
          content: ' "';
        }
      }
    }
  }
}
