.header {
  width: 100%;
  background-color: $blue-piv;
  font-size: 1rem;

  .logo {
    width: 140px;
    height: 40px;
  }

  .h-container {
    color: white;
    padding: 18px 20px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    height: 72px;

    #profile-user {
      cursor: pointer;
    }

    .h-left,
    .h-right {
      display: flex;
      gap: 24px;
    }

    .h-left {
      gap: 50px;
      align-items: center;

      p {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        color: white;
      }
    }

    .h-right {
      p {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        color: white;
      }

      a {
        color: white;
        text-decoration: none;
        font-size: 14px;
        font-family: Roboto, sans-serif;
      }
    }
  }

  .h-loggedoff-banner {
    background-color: $blue-pale;
    color: $blue-fonce;

    &.nx-banner-yellow {
      background-color: $jaune-pale;
      color: $blue-fonce;
    }

    .nx-loggedoff-banner-wrapper {
      font-size: 0;
      line-height: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 24px 0;

      p {
        font-size: 16px;
        line-height: 100%;
        margin: 0 0 0 12px;
      }
    }
  }

  .h-top-nav {
    width: 100%;
    background-color: $blue-fonce;
    box-shadow: rgba(0 0 0 / 4%) 0 3px 5px;
    position: relative;

    .menu {
      font-family: "Open sans", sans-serif;
      padding: 0 20px;
      padding-left: 5px;
      list-style: none;
      display: flex;
      justify-content: space-between;
      max-width: 1200px;
      margin: 0 auto;
      min-height: 4.5rem;
    }

    & ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      align-items: flex-end;
      line-height: 44px;

      li {
        display: flex;
        align-items: flex-start;
        padding: 0 2px;

        .nav-link {
          color: #fff;
          border-bottom: 4px solid transparent;
          font-size: 1.125rem;
          font-weight: 400;
          text-decoration: none;
          padding: 0 14px;

          @media (max-width: 992px) {
            padding: 0 8px;
          }

          &:hover {
            border-bottom: 4px solid $gris-clair;
          }

          &.active {
            border-bottom: 4px solid $gris-clair;
            font-weight: 700;
          }
        }
      }
    }

    .h-right {
      display: flex;
      align-items: center;

      .icon-wrap {
        .topnav-link {
          display: flex;
          height: 40px;
          justify-content: center;
          align-items: center;

          &:hover {
            background-color: $blue-piv;
          }
        }
      }

      #language-selector {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    div[data-submenu-for="profile-user"] {
      background-color: white;
      color: $blue-moyen;
      position: absolute;
      box-shadow: 0 4px 16px #22365429;
      display: none;
      z-index: 7;

      .submenu-link {
        color: $blue-piv;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        gap: 8px;
        padding: 8px 12px;

        > span {
          white-space: nowrap;
          display: block;
          padding-left: 6px;
        }

        &:hover {
          background-color: #b9d7f0;
        }
      }

      &::after {
        content: "";
        border: 10px solid transparent;
        border-top: 0;
        border-bottom-color: white;
        display: none;
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        left: 17%;
        margin-left: -5px;
        margin-top: -10px;
      }
    }

    .messages {
      color: white;
    }

    .red-dot {
      color: $rose;
      position: absolute;
      margin-top: -25px;
      margin-right: -25px;
      font-size: 25px;
    }

    #profile-user {
      color: #fff;
      display: flex;
      cursor: pointer;

      .user-icon {
        display: flex;
        justify-items: flex-end;
        align-items: center;

        @media (min-width: 1200px) {
          display: none;
        }
      }

      .user-button {
        border: 1px solid #fff;
        padding: 8px 5px;

        @media (min-width: 768px) and (max-width: 1199px) {
          display: none;
        }
      }

      .nx-w,
      .nx-b,
      .nx-ui {
        width: 16px;
        transition: transform 0.3s;
      }

      .nx-w {
        display: inline-block;
      }

      .nx-b,
      .nx-ui {
        display: none;
      }

      &:hover {
        .user-button {
          background-color: #fff;
          color: $blue-piv;
        }

        .nx-w,
        .nx-ui {
          display: none;
        }

        .nx-b {
          display: inline-block;
        }
      }

      &.nx-open {
        .user-button {
          background-color: #f6f7f8;
          color: $blue-fonce;
        }

        .nx-ui {
          display: inline-block;
          transform: scaleY(-1);
        }

        .nx-w,
        .nx-b {
          display: none;
        }
      }

      > span {
        padding: 0 8px 0 0;
      }
    }
  }

  .menu-icon {
    display: none;
    cursor: pointer;
  }

  [data-target*="sub-menu-"] {
    display: flex;
    gap: 5px;
  }

  .nx-profile-submenu {
    min-width: 100%;
    position: absolute;
    top: 100%;
    right: 0;
  }

  .sub-menu {
    background-color: #fff;
    position: absolute;
    list-style: none;
    width: 100%;
    height: 4.5rem;
    box-shadow: 0 4px 16px #22365429;
    z-index: 5;
    top: 72px;
    left: 0;
    display: none;

    .container {
      height: 100%;
      align-content: center;
    }

    &__list {
      max-width: 1200px;
      margin: 0 auto !important;
      position: relative;
      background-color: #fff;
      height: 100%;
    }

    &__link {
      padding: 14px !important;
      color: $blue-piv !important;
      top: 0;

      &.active {
        border-bottom: 4px solid $gris-clair;
        font-weight: 700;
      }
    }
  }

  @include md {
    .h-container {
      .h-left {
        p {
          display: none;
        }

        .logo {
          width: 128px;
        }
      }

      .h-right {
        gap: 18px;
      }
    }

    .h-top-nav {
      display: none;
      height: 100vh;
      position: fixed;
      background: white;

      ul {
        flex-direction: column;
        align-items: initial;
        padding: 0 25px;

        li {
          a {
            width: 100%;
          }
        }
      }
    }

    .menu-icon {
      display: flex;
    }
  }
}

.header-mob {
  display: none;
}

@media (max-width: 768px) {
  .header {
    display: none;
  }

  .header-mob {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $blue-piv;
    font-size: 10pt;

    .logo {
      width: 140px;
      height: 40px;
    }

    .mob-nav-panel {
      display: none;
      background-color: white;
      position: absolute;
      width: 100%;
      top: 70px;
      height: calc(100vh - 25px);
      z-index: 999999;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: block;

        li {
          font-size: 16px;
          font-weight: bold;
          color: $blue-piv !important;
          width: 100%;
          border-bottom: 1px solid $gris-clair;

          .sub-link {
            padding: 16px 8px;
            display: flex;
            justify-content: space-between;
            border-bottom: none;
          }

          .arrow-up {
            display: none;
          }

          .dropdown {
            flex-direction: column;

            .dropdown-label {
              display: flex;
              justify-content: space-between;
              padding: 16px 8px;

              &.active {
                background-color: $blue-pale;
              }
            }

            .dropdown-sub-nav {
              display: none;
              padding-left: 24px;

              .sub-sub-link {
                padding: 8px 8px 8px 16px;
                font-weight: 400;
                display: flex;
              }
            }
          }

          &.active,
          &[data-state="show"] {
            .sub-link {
              background-color: $blue-pale;
            }

            .arrow-up {
              display: inline-block;
            }

            .arrow-down {
              display: none;
            }

            .dropdown-sub-nav {
              display: block;

              .active {
                .sub-sub-link {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }

    .h-top-nav {
      width: 100%;
      background-color: white;
      box-shadow: rgba(0 0 0 / 4%) 0 3px 5px;
      display: none;
      height: 100vh;
      position: fixed;

      ul {
        margin: 0;
        list-style: none;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: initial;
        padding: 0 25px;

        li {
          display: inherit;

          a {
            padding: 25px;
            color: $blue-fonce;
            text-decoration: none;
            font-size: 12pt;
            border-bottom: 4px solid $blue-fonce;
            width: 100%;

            &:hover {
              border-bottom: 4px solid $gris-clair;
              padding-bottom: 21px;
            }
          }

          &.active {
            a {
              border-bottom: 4px solid $blue-fonce;
              padding-bottom: 21px;
            }
          }
        }
      }
    }

    .menu-icon {
      display: flex;
      cursor: pointer;
    }

    img.menu-icon {
      margin-left: 16px;
    }

    .h-container {
      color: white;
      padding: 18px 24px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      height: 72px;
      width: 100%;

      p {
        margin-bottom: 0;
        display: flex;
        align-items: center;
      }

      #profile-user {
        cursor: pointer;
      }

      .h-left,
      .h-right {
        display: flex;
      }

      .h-left {
        gap: 50px;

        p {
          display: none;
        }

        .logo {
          width: 128px;
        }
      }

      .h-right {
        align-items: center;

        .icon-wrap {
          .topnav-link {
            display: flex;
            height: 40px;
            justify-content: center;
            align-items: center;

            &:hover {
              background-color: $blue-piv;
            }
          }
        }

        #language-selector {
          &:hover {
            text-decoration: underline;
          }
        }
      }

      div[data-submenu-for="mobile-profile-user"] {
        background-color: white;
        color: $blue-moyen;
        position: absolute;
        box-shadow: rgba(0 0 0 / 15%) 0 3px 3px 0;
        display: none;
        z-index: 7;

        .submenu-link {
          display: flex;
          gap: 8px;
          color: $blue-moyen;
          padding: 8px 16px;

          &:hover {
            background-color: $blue-pale;
          }
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 17%;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-bottom-color: white;
          border-top: 0;
          margin-left: -5px;
          margin-top: -10px;
        }
      }
    }

    .red-dot {
      color: $rouge;
      position: absolute;
      margin-top: -40px;
      right: 5px;
      font-size: 25px;

      &::before {
        content: "•";
      }
    }
  }
}
