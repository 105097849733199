.breadcrumbs-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: 768px) {
    padding: 0 24px;
  }

  @media (min-width: 576px) {
    max-width: 576px;
  }

  @media (min-width: 768px) {
    max-width: 768px;
  }

  @media (min-width: 992px) {
    max-width: 992px;
  }

  @media (min-width: 1200px) {
    max-width: 1200px;
  }

  @media (min-width: 1400px) {
    max-width: 1200px;
  }
}

.breadcrumbs {
  font-family: "Open sans", sans-serif;
  background-color: transparent;
  display: inline-flex;
  list-style: none;
  font-size: 12px;
  align-items: center;
  flex-wrap: wrap;
  margin: 1.6rem 0;
  color: $blue-fonce;

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    padding: 0;
    display: inline;
    font-size: 12px;

    &:last-child {
      font-weight: bold;
    }
  }

  a {
    color: $blue-fonce !important;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  li:not(:last-child) a::after {
    content: "";
    display: inline-block;
    background-image: url("https://design.quebec.ca/typo3conf/ext/gabarit_pgu/Resources/Public/Images/Templates/chevron-fil.svg");
    width: 4px;
    height: 7px;
    background-size: cover;
    top: -1px;
    margin-left: 8px;
    margin-right: 8px;
    border: 0;
    transform: none;
  }
}

@media (max-width: 768px) {
  .breadcrumbs {
    margin-bottom: 72px;
  }

  .breadcrumbs li:not(.curitem) {
    display: none;
  }

  .curitem::before {
    content: "";
    display: inline-block;
    background-image: url("https://design.quebec.ca/typo3conf/ext/gabarit_pgu/Resources/Public/Images/Templates/chevron-fil.svg");
    width: 4px;
    height: 7px;
    background-size: cover;
    top: -1px;
    margin-left: 8px;
    margin-right: 8px;
    border: 0;
    transform: none;
  }
}
