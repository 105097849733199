.public-project-details {
  color: $blue-fonce;

  .nx-project-detail-company-title {
    color: $blue-fonce;
    font-size: 34px;
    font-weight: 500;
    line-height: 120%;
    padding: 20px 0 32px;
  }

  .nx-project-detail-dates {
    .nx-project-detail-dates-line {
      display: flex;
      align-items: center;
      margin: 6px 0;

      > img.nx-icon {
        margin: 0 6px 0 0;
      }

      > strong.nx-strong {
        font-weight: 600;
        flex: 0 1 170px;
      }
    }
  }

  .project-do-details {
    padding-left: 24px;
  }

  .nx-project-detail-value-blue {
    color: $blue-fonce;
    font-size: 14pt;
    font-weight: 300;
    line-height: 130%;
    margin: 16px 0;
  }

  .nx-project-detail-value-blue a {
    text-decoration: underline;
  }

  .nx-project-detail-value-blue a:hover {
    text-decoration: none;
  }

  .underlined-title {
    font-size: 21pt;
    border-bottom: 1px solid $blue-piv;
    display: flex;
    color: $blue-fonce;
    font-weight: bold;
    margin: 32px 0;
    padding: 0;
    justify-content: space-between;
  }

  .icon-bubble {
    float: right;

    img {
      background-color: transparent;
      box-sizing: content-box;
      border-radius: 0;
      width: 32px;
      height: 32px;
      padding: 0;
    }
  }

  .nx-project-detail-address {
    .ids-info {
      margin-top: 18px;

      li {
        align-items: center;
      }
    }

    .nx-project-detail-address-mrc {
      font-size: 16px;
      line-height: 140%;

      img {
        vertical-align: middle;
      }

      a {
        color: $blue-fonce;
        vertical-align: middle;
      }
    }

    .nx-project-detail-company-category {
      color: $blue-piv;
      margin: 12px 0;
    }
  }

  .nx-project-detail-description-title {
    color: $blue-fonce;
    font-size: 34px;
    font-weight: 500;
    line-height: 120%;
    padding: 20px 0;
  }

  .nx-project-detail-description-text {
    color: $blue-fonce;
    font-size: 13pt;
    font-weight: 400;
    line-height: 150%;
    margin: 0 0 24px;
  }

  .nx-project-detail-address-info {
    color: $blue-fonce;
    font-size: 13pt;
    font-weight: 400;
    line-height: 150%;
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin: 12px 0;
  }

  .nx-project-detail-montant {
    color: $blue-fonce;
    font-size: 13pt;
    font-weight: 400;
    line-height: 150%;
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin: 12px 0;

    > * {
      vertical-align: middle;
    }
  }

  .nx-project-detail-tags {
    margin: 12px 0;

    > div {
      background-color: #dae6f0;
      color: $blue-piv;
      font-size: 11pt;
      font-weight: 500;
      line-height: 120%;
      display: inline-block;
      padding: 12px;
      margin: 12px 12px 12px 0;
    }
  }

  .nx-project-detail-documents {
    border-top: 2px solid $blue-fonce;
    padding: 32px 0;
    margin: 32px 0 0;

    h3 {
      margin: 0 0 20px;
    }

    .nx-project-detail-documents-seao {
      border-bottom: 2px solid #dddfe4;
      padding: 12px 0 32px;

      a {
        text-decoration: underline;
      }

      a:hover {
        text-decoration: none;
      }
    }

    .nx-project-detail-documents-files {
      border-bottom: 2px solid #dddfe4;
      padding: 32px 0;

      .doc-link {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: flex-start;
        padding: 8px 0;

        a {
          text-decoration: underline;
        }

        a:hover {
          text-decoration: none;
        }
      }
    }
  }

  .company-name {
    color: $blue-fonce;
    font-size: 13pt;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 4px;
  }

  .project-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid $blue-pale;
    flex: 0 25%;
    aspect-ratio: 16/11;
  }

  .map-demo {
    margin-bottom: 0 !important;
  }

  .nx-project-detail-map {
    margin: 32px 0 0;
  }

  .nx-icon {
    width: 24px;
    height: 24px;
  }
}
