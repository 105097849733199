.nx-info-link {
  word-wrap: break-word;
  overflow: hidden;

  a:hover {
    color: $blue-piv;
    text-decoration: underline;
  }
}

.nx-page-company-detail {
  .social-icons {
    margin: 12px 0;

    li {
      font-size: 0;
      line-height: 0;

      a {
        background-color: $blue-piv;
        display: inline-block;
        width: 32px;
        height: 32px;
        padding: 4px;
      }
    }
  }

  .ids-info {
    color: $blue-fonce;
    margin: 32px 0;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin: 0 6px 0 0;

      img {
        align-self: self-start;
        margin: 2px 4px 0 0;
      }

      .nx-info-txt {
        align-self: self-start;
      }
    }
  }

  .scian-info-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 0;
    margin-top: 32px;

    @media (max-width: 776px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 576px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .nx-company-detail-infos {
    > div {
      margin: 32px 0;
    }

    .nx-company-detail-title {
      color: $blue-fonce;
      font-size: 1.75rem;
      font-weight: bold;
      padding: 0 0 8px;
      margin: 32px 0 28px;
      font-family: Roboto, "Open sans", sans-serif;
    }

    .nx-company-detail-value-blue {
      color: $blue-fonce;
      font-size: 14pt;
      font-weight: 300;
      line-height: 130%;
      margin: 16px 0;
    }

    .nx-company-detail-value-blue a {
      text-decoration: underline;
    }

    .nx-company-detail-value-inlineblock {
      span {
        font-size: 13pt;
        font-weight: 300;
        line-height: 130%;
        display: inline-block;
        margin: 16px 16px 16px 0;
      }
    }

    .nx-company-detail-description {
      color: $blue-fonce;
      font-size: 13pt;
      font-weight: 350;
      line-height: 130%;
      max-width: 830px;
      margin: 32px auto 32px 0;
      padding: 0;
    }

    .nx-company-detail-region {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 32px 0;

      .value {
        font-size: 13pt;
        font-weight: 300;
        line-height: 130%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 3px 0 0 12px;
        color: $blue-fonce;

        img {
          margin: 0 4px 0 0;
        }
      }
    }
  }

  .nx-company-detail-workers {
    .nx-company-detail-title {
      color: $blue-fonce;
      font-size: 28px;
      font-weight: bold;
      padding: 0 0 8px;
      margin: 32px 0 28px;
      font-family: Roboto, "Open sans", sans-serif;
    }

    .row {
      margin: 0 calc(var(--bs-gutter-x) * 0.5 / -1);

      .col-sm-12 {
        flex: 1 0 auto;
        margin: 12px 0;
      }
    }

    .nx-company-detail-name {
      color: $blue-fonce;
      font-size: 16pt;
      font-weight: 600;
      line-height: 130%;
      margin: 0 0 12px;
    }

    .nx-company-detail-poste {
      color: $blue-fonce;
      font-size: 13pt;
      font-weight: 300;
      line-height: 130%;
    }

    .address-box {
      word-break: break-word;
      height: 100%;

      .i-row {
        img {
          align-self: self-start;
          width: 20px;
          height: 20px;
          margin: 2px 0 0;
        }
      }
    }
  }

  .nx-page-company-detail-succursale {
    margin: 0 calc(var(--bs-gutter-x) * 0.5 / -1) 32px;

    .address {
      margin: 12px 0;

      .address-box {
        margin: 12px 0;
      }
    }

    .nx-company-detail-succursale-title {
      color: $blue-fonce;
      font-size: 18pt;
      font-weight: 600;
      line-height: 130%;
      margin: 0 0 12px;
      font-family: Roboto, "Open sans", sans-serif;
    }
  }

  .documents-and-hyperlinks {
    margin: 32px 0;

    .nx-company-detail-title {
      color: $blue-fonce;
      font-size: 28px;
      font-weight: bold;
      padding: 0 0 8px;
      margin: 32px 0 28px;
      font-family: Roboto, "Open sans", sans-serif;
    }
  }

  .nx-company-detail-boncoups {
    .nx-company-detail-title {
      border-bottom: 2px solid #3b4d67;
      color: $blue-fonce;
      font-size: 28px;
      font-weight: bold;
      padding: 0 0 8px;
      margin: 32px 0 28px;
      font-family: Roboto, "Open sans", sans-serif;
    }

    .nx-company-detail-boncoups-title {
      color: $blue-fonce;
      font-size: 18pt;
      font-weight: 600;
      line-height: 130%;
      margin: 0 0 12px;
      font-family: Roboto, "Open sans", sans-serif;
    }

    .nx-company-detail-boncoups-company {
      color: $blue-fonce;
      font-size: 14pt;
      font-weight: 600;
      line-height: 130%;
      margin: 0 0 20px;
    }

    .date {
      color: $blue-fonce;
      font-size: 11pt;
      font-weight: 300;
      line-height: 130%;
      margin: 0 0 6px;
    }

    .nx-company-detail-boncoups-description {
      color: $blue-fonce;
      font-size: 12pt;
      font-weight: 300;
      line-height: 150%;
      margin: 0 0 12px;
    }

    .btn-icon-link {
      color: $blue-piv;
      font-size: 11pt;
      font-weight: 300;
      line-height: 130%;
    }

    .row {
      margin: 0 calc(var(--bs-gutter-x) * 0.5 / -1);

      .col-sm-12 {
        margin: 12px 0;
      }
    }
  }

  .nx-company-detail-projects {
    padding: 0 0 32px;
    margin: 32px 0;

    .nx-company-detail-title {
      border-bottom: 2px solid $gris-fonce;
      color: $blue-fonce;
      font-size: 28px;
      font-weight: bold;
      padding: 0 0 8px;
      margin: 32px 0 28px;
      font-family: Roboto, "Open sans", sans-serif;
    }

    .nx-company-detail-projects-title {
      color: $blue-fonce;
      font-size: 18pt;
      font-weight: 600;
      line-height: 130%;
      margin: 0 0 12px;
      font-family: Roboto, "Open sans", sans-serif;
    }

    .date {
      color: $blue-fonce;
      font-size: 12pt;
      font-weight: 300;
      line-height: 130%;
      margin: 12px 0;
    }

    .nx-company-detail-projects-description {
      color: $blue-fonce;
      font-size: 12pt;
      font-weight: 300;
      line-height: 150%;
      margin: 0 0 12px;
    }

    .address-info {
      color: $blue-fonce;
      font-size: 12pt;
      font-weight: 300;
      line-height: 150%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .address {
        margin: 0 0 0 12px;
      }
    }

    .row {
      margin: 0 calc(var(--bs-gutter-x) * 0.5 / -1);

      .col-sm-12 {
        margin: 12px 0;
      }
    }
  }

  .nx-company-details-files-videos {
    border-top: 2px solid $blue-fonce;

    .title-videos-files {
      border-bottom: 0;
    }

    .doc-link {
      margin-bottom: 8px;

      a {
        text-decoration: underline;
        color: #095797;
      }
    }
  }
}

.nx-company-detail-succursale-address {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  color: $blue-fonce;
}

.nx-page-company-detail-succursale .address-box .i-row a {
  text-decoration: none;
}

.nx-page-company-detail-succursale .address-box .i-row a:hover {
  text-decoration: underline;
}

.nx-page-company-detail-succursale .address-box .i-row a:active {
  opacity: 0.7;
}

.nx-page-company-detail .nx-company-detail-infos .nx-company-detail-value-blue a:hover {
  text-decoration: none;
}
