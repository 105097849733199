.tooltip-container {
  .tooltip-icon {
    cursor: pointer;
  }

  .tooltip-box,
  .nx-tooltip-box {
    display: none;
    border: 1px solid #c7c8c9;
    padding: 16px 24px 16px 16px;
    font-size: 9pt;
    position: absolute;
    width: 320px;
    max-height: 160px;
    background: white;
    z-index: 1;
    margin-left: 35px;
    margin-top: -46px;
    box-shadow: rgba(99 99 99 / 20%) 0 2px 8px 0;
  }

  .nx-tooltip-box {
    width: auto;
    padding: 8px 16px;
    margin-top: -38px;
  }

  .tooltip-box::before,
  .nx-tooltip-box::before {
    content: " ";
    position: absolute;
    left: -9px;
    top: 26px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 8px 7px 0;
    border-color: transparent #c7c8c9 transparent transparent;
  }

  .tooltip-box::after,
  .nx-tooltip-box::after {
    content: " ";
    position: absolute;
    left: -8px;
    top: 26px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 8px 7px 0;
    border-color: transparent white transparent transparent;
  }

  .nx-tooltip-box::before,
  .nx-tooltip-box::after {
    top: 20px;
  }

  .tooltip-close {
    position: absolute;
    right: 7px;
    top: 5px;
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .tooltip-cont,
  .nx-tooltip-cont {
    overflow-y: scroll;
    height: inherit;
    max-height: 118px;
    width: calc(100% + 17px);
    padding-right: 8px;
    padding-bottom: 8px;
    margin-top: 10px;

    &::-webkit-scrollbar-track {
      border-radius: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: #095797;
    }
  }

  .nx-tooltip-cont {
    overflow: auto;
    width: 100%;
    padding: 0 0 8px;
  }
}
